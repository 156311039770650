import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Sankey_Entity } from "../../entities/sankey";
import { budgetApi } from "../api/budgetApi";

const sankeysAdapter = createEntityAdapter<Sankey_Entity>();
const initialState = sankeysAdapter.getInitialState();

export const sankeyEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getSankeys: build.query<EntityState<Sankey_Entity>, void>({
      query: () => `sankeys`,
      transformResponse: (responseData: Sankey_Entity[]) => {
        return sankeysAdapter.setAll(initialState, responseData);
      },
      providesTags: ["sankeys"],
    }),
    getSankey: build.query<Sankey_Entity, number>({
      query: (id) => `sankeys/${id}`,
      providesTags: (result, error, id) => [{ type: "sankeys", id }],
    }),
    createSankey: build.mutation<Sankey_Entity, Partial<Sankey_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `sankeys`,
      }),
      invalidatesTags: ["sankeys"],
    }),
    updateSankey: build.mutation<
      void,
      { id: number; body: Partial<Sankey_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `sankeys/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "sankeys", id },
        "sankeys",
      ],
    }),
    deleteSankey: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `sankeys/${id}`,
      }),
      invalidatesTags: ["sankeys"],
    }),
  }),
});

export const {
  useGetSankeyQuery,
  useGetSankeysQuery,
  useCreateSankeyMutation,
  useDeleteSankeyMutation,
  useUpdateSankeyMutation,
} = sankeyEndpoints;

export default sankeyEndpoints;
