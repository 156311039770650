import { Add, Search } from "@mui/icons-material";
import { Theme } from "@mui/joy";
import { Skeleton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { NerdAppBarProps } from "@nerdjs/nerd-ui";
import moment from "moment";
import { AppConfig } from "../../environement";
import { useScores } from "../../redux/score/scoreHooks";
import { NerdAppBarDarkModeButton } from "../nerdAppBarDarkModeButton/nerdAppBarDarkModeButton";
import NerdAppsMenuWrapper from "../nerdAppsMenuWrapper/nerdAppsMenuWrapper";
import { NerdUserMenu } from "../nerdUserMenu/nerdUserMenu";
import { ScoreChip } from "../scores/scoresDataGrid";
import { RouterConfig } from "./routerConfig";

/**
 * @param {Theme} theme App Theme
 * @returns {NerdAppBarProps} NerdAppBarProps
 */
export function appBarConfig(theme: Theme): NerdAppBarProps {
  return {
    variant: "dense",
    title: "WID Budget",
    logo:
      theme.palette?.mode === "light"
        ? AppConfig.app.logo
        : AppConfig.app.logoDarkMode,
    content: [
      <Box key="scores" sx={{ display: "flex", gap: 1 }}>
        <Score index="lastMonth" key="lastMonth" />
        <Score key="latest" />
      </Box>,
      <div style={{ flexGrow: 1 }} key={"nerdAppMenustylingDiv"} />,
      <NerdAppsMenuWrapper key={"NerdAppsMenuWrapper"} />,
      <NerdAppBarDarkModeButton key={"nerdAppBarDarkModeButton"} />,
      <NerdUserMenu key={"myAccountButton"} />,
    ],
  };
}

function Score({ index = "latest" }: { index?: "latest" | "lastMonth" }) {
  const { scores, isLoading } = useScores();
  let score = scores?.length && scores[scores.length - 1];
  if (index === "lastMonth" && (scores?.length ?? 0) > 1)
    score = scores?.length && scores[scores.length - 2];

  if (isLoading || !score) return <Skeleton />;

  return (
    <Tooltip
      title={`${moment(score.date).format("MMM YY")} | PL:${
        score.plGrade
      }, Quota:${score.quotaGrade}`}
    >
      <Box>
        <ScoreChip size={"lg"} score={score.grade} />
      </Box>
    </Tooltip>
  );
}
