import { createAction, nanoid } from "@reduxjs/toolkit";

export const setSelectedReportID = createAction(
  "setSelectedReportID",
  function prepare(reportID?: number) {
    return {
      payload: {
        reportID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
