import { Box } from "@mui/system";
import { ReactElement } from "react";
import TransactionGroupsDataGrid from "../hooks/transactionGroups/transactionGroupsDataGrid";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function TransactionGroupsRoute(): ReactElement {
  return (
    <Box sx={{ p: 1, display: "flex", flex: 1 }}>
      <TransactionGroupsDataGrid />
    </Box>
  );
}
