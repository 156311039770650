import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button } from "@mui/joy";
import { Paper } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useConfirm } from "material-ui-confirm";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Report_Entity } from "../../entities/report";
import { setSelectedReportID } from "../../redux/report/reportActions";
import {
  useCreateReportMutation,
  useDeleteReportMutation,
  useUpdateReportMutation,
} from "../../redux/report/reportEndpoints";
import { useReports } from "../../redux/report/reportHooks";

/**
 * Report
 *
 * @returns {null} Report
 */
export default function ReportsDataGrid() {
  const { reports, isLoading } = useReports();
  const [updateReport] = useUpdateReportMutation();
  const [deleteReport] = useDeleteReportMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  const onReportUpdate = async (
    _newReport: Report_Entity,
    _oldReport: Report_Entity
  ) => {
    return new Promise<Report_Entity>((resolve) => {
      updateReport({
        id: _newReport.id,
        body: _newReport,
      });
      setTimeout(() => {
        resolve({ ..._newReport });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1, display: "flex" }}>
      <DataGridPro
        density="compact"
        apiRef={apiRef}
        loading={isLoading}
        processRowUpdate={onReportUpdate}
        rows={reports ?? []}
        columns={[
          {
            field: "id",
            headerName: "ID",
            editable: false,
            type: "number",
            width: 40,
          },
          {
            field: "name",
            headerName: "Name",
            editable: true,
            type: "string",
            flex: 1,
          },
          {
            field: "view",
            headerName: "",
            editable: false,
            type: "string",
            width: 160,
            align: "right",
            renderCell: (p) => (
              <Button
                size="sm"
                onClick={() => {
                  navigate(`${p.row.id}`);
                }}
                endDecorator={<i className="fa-solid fa-chevron-right"></i>}
              >
                View Report
              </Button>
            ),
          },
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (p) => [
              <GridActionsCellItem
                key="delete"
                icon={<DeleteIcon />}
                label={`Delete`}
                onClick={() => {
                  confirm({
                    description: `You are about to delete a report`,
                  })
                    .then(() => {
                      deleteReport(p.row.id);
                    })
                    .catch(() => {
                      /* ... */
                    });
                }}
                color="inherit"
              />,
            ],
          },
        ]}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: ReportToolbar,
        }}
        initialState={{
          pinnedColumns: { left: ["id"], right: ["actions"] },
        }}
      />
    </Paper>
  );
}

function ReportToolbar() {
  const [createReport] = useCreateReportMutation();

  const handleNewReport = () => {
    const newReport = {
      id: -1,
      name: "",
      createdAt: "",
      updatedAt: "",
    };
    createReport(newReport);
  };

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, alignItems: "start", gap: 1 }}
    >
      <Button variant="soft" onClick={handleNewReport}>
        + New
      </Button>
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}
