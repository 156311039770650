import { Box } from "@mui/system";
import { ReactElement } from "react";
import ExpenseTypesDataGrid from "../hooks/expenseTypes/expenseTypesDataGrid";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function ExpenseTypesRoute(): ReactElement {
  return (
    <Box sx={{ p: 1, display: "flex", flex: 1 }}>
      <ExpenseTypesDataGrid />
    </Box>
  );
}
