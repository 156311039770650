/**
 * Sankey_Entity type guard.
 *
 * @param {any} sankeyJson Sankey object from API
 * @returns {boolean} Return true if type is Sankey_Entity
 */

import { Flow_Entity } from "../flow";
import { Node_Entity } from "../node";

export default class Sankey_Entity {
  constructor(sankeyJson: Sankey_Entity) {
    this.id = sankeyJson.id;
    this.name = sankeyJson.name;
    this.description = sankeyJson.description;
    this.dateFrom = sankeyJson.dateFrom;
    this.dateTo = sankeyJson.dateTo;
    this.nodes = sankeyJson.nodes;
    this.flows = sankeyJson.flows;
    this.createdAt = sankeyJson.createdAt;
    this.updatedAt = sankeyJson.updatedAt;
  }

  id: number;
  name: string;
  description: string;
  dateFrom: string;
  dateTo: string;
  nodes: Array<Node_Entity>;
  flows: Array<Flow_Entity>;
  createdAt: string;
  updatedAt: string;
}
