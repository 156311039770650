import { Box } from "@mui/system";
import { ReactElement } from "react";
import ScoresDataGrid from "../hooks/scores/scoresDataGrid";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function ScoresRoute(): ReactElement {
  return (
    <Box sx={{ p: 1, display: "flex", flex: 1 }}>
      <ScoresDataGrid />
    </Box>
  );
}
