import { useMemo } from "react";
import { TransactionGroup } from "../../entities/transactionGroup";
import {
  useGetTransactionGroupQuery,
  useGetTransactionGroupsQuery,
} from "./transactionGroupEndpoints";

export function useTransactionGroup(
  transactionGroupID: number | undefined,
  opt?: { skip?: boolean }
) {
  const transactionGroupQuery = useGetTransactionGroupQuery(
    transactionGroupID ?? 0,
    {
      skip: opt?.skip || (transactionGroupID ?? 0) <= 0,
    }
  );
  const transactionGroupEntity = transactionGroupQuery.data;

  return useMemo(() => {
    const ret: typeof transactionGroupQuery & {
      transactionGroup?: TransactionGroup | undefined;
    } = {
      ...transactionGroupQuery,
    };
    if (transactionGroupEntity)
      ret.transactionGroup = new TransactionGroup(transactionGroupEntity);
    return ret;
  }, [transactionGroupEntity]);
}

export function useTransactionGroups() {
  const transactionGroupQuery = useGetTransactionGroupsQuery();
  const transactionGroupEntitiesMap = transactionGroupQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof transactionGroupQuery & {
      transactionGroups?: TransactionGroup[];
      transactionGroupsMap?: { [id: number]: TransactionGroup };
    } = {
      ...transactionGroupQuery,
      transactionGroups: [],
      transactionGroupsMap: {},
    };
    if (transactionGroupEntitiesMap) {
      const transactionGroups = [];
      const transactionGroupsMap = {};

      for (const key in transactionGroupEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(transactionGroupEntitiesMap, key)
        ) {
          const c = transactionGroupEntitiesMap[key];
          const transactionGroup = new TransactionGroup(c);
          transactionGroups.push(transactionGroup);
          transactionGroupsMap[transactionGroup.id] = transactionGroup;
        }
      }
      ret.transactionGroups = transactionGroups;
      ret.transactionGroupsMap = transactionGroupsMap;
    }

    return ret;
  }, [transactionGroupEntitiesMap]);
}
