import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button } from "@mui/joy";
import { Paper } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useConfirm } from "material-ui-confirm";
import { Account_Entity } from "../../entities/account";
import {
  useCreateAccountMutation,
  useDeleteAccountMutation,
  useUpdateAccountMutation,
} from "../../redux/account/accountEndpoints";
import { useAccounts } from "../../redux/account/accountHooks";

/**
 * Account
 *
 * @returns {null} Account
 */
export default function AccountsDataGrid() {
  const { accounts, isLoading } = useAccounts();
  const [updateAccount] = useUpdateAccountMutation();
  const [deleteAccount] = useDeleteAccountMutation();

  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  const onAccountUpdate = async (
    _newAccount: Account_Entity,
    _oldAccount: Account_Entity
  ) => {
    return new Promise<Account_Entity>((resolve) => {
      updateAccount({
        id: _newAccount.id,
        body: _newAccount,
      });
      setTimeout(() => {
        resolve({ ..._newAccount });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1, display: "flex" }}>
      <DataGridPro
        density="compact"
        apiRef={apiRef}
        loading={isLoading}
        processRowUpdate={onAccountUpdate}
        rows={accounts ?? []}
        columns={[
          {
            field: "id",
            headerName: "ID",
            editable: false,
            type: "number",
            width: 40,
          },
          {
            field: "name",
            headerName: "Name",
            editable: true,
            type: "string",
            flex: 1,
          },
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (p) => [
              <GridActionsCellItem
                key="delete"
                icon={<DeleteIcon />}
                label={`Delete`}
                onClick={() => {
                  confirm({
                    description: `You are about to delete a account`,
                  })
                    .then(() => {
                      deleteAccount(p.row.id);
                    })
                    .catch(() => {
                      /* ... */
                    });
                }}
                color="inherit"
              />,
            ],
          },
        ]}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: AccountToolbar,
        }}
        initialState={{
          pinnedColumns: { left: ["id"], right: ["actions"] },
        }}
      />
    </Paper>
  );
}

function AccountToolbar() {
  const [createAccount] = useCreateAccountMutation();

  const handleNewAccount = () => {
    const newAccount = {
      id: -1,
      name: "",
      createdAt: "",
      updatedAt: "",
    };
    createAccount(newAccount);
  };

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, alignItems: "start", gap: 1 }}
    >
      <Button variant="soft" onClick={handleNewAccount}>
        + New
      </Button>
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}
