import Transaction_Entity from "./transaction";
export default class Transaction extends Transaction_Entity {
  static fromList(transactionsJSON: unknown): Array<Transaction> {
    const transactions: Transaction[] = [];
    if (transactionsJSON)
      Array.isArray(transactionsJSON) &&
        transactionsJSON.forEach((transactionJSON) => {
          transactions.push(new Transaction(transactionJSON));
        });
    return transactions;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
