/**
 * ExpenseType_Entity type guard.
 *
 * @param {any} expenseTypeJson ExpenseType object from API
 * @returns {boolean} Return true if type is ExpenseType_Entity
 */
function validator(
  expenseTypeJson: unknown
): expenseTypeJson is ExpenseType_Entity {
  if (typeof expenseTypeJson === "object" && expenseTypeJson != null) {
    ExpenseType_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(expenseTypeJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class ExpenseType_Entity {
  static requiredFields = ["id", "refID", "name", "createdAt", "updatedAt"];

  constructor(expenseTypeJson: unknown) {
    if (validator(expenseTypeJson)) {
      this.id = expenseTypeJson.id;
      this.refID = expenseTypeJson.refID;
      this.name = expenseTypeJson.name;
      this.createdAt = expenseTypeJson.createdAt;
      this.updatedAt = expenseTypeJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ ExpenseType_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  refID: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}
