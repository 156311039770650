import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { Flow_Entity } from "../../entities/flow";
import { budgetApi } from "../api/budgetApi";

const flowsAdapter = createEntityAdapter<Flow_Entity>();
const initialState = flowsAdapter.getInitialState();

export const flowEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getFlows: build.query<EntityState<Flow_Entity>, void>({
      query: () => `flows`,
      transformResponse: (responseData: Flow_Entity[]) => {
        return flowsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["flows"],
    }),
    getFlow: build.query<Flow_Entity, number>({
      query: (id) => `flows/${id}`,
      providesTags: (result, error, id) => [{ type: "flows", id }],
    }),
    createFlow: build.mutation<Flow_Entity, Partial<Flow_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `flows`,
      }),
      invalidatesTags: ["flows", "sankeys"],
    }),
    updateFlow: build.mutation<
      void,
      { id: number; body: Partial<Flow_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `flows/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "flows", id },
        "flows",
        "sankeys",
      ],
    }),
    deleteFlow: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `flows/${id}`,
      }),
      invalidatesTags: ["flows", "sankeys"],
    }),
  }),
});

export const {
  useGetFlowQuery,
  useGetFlowsQuery,
  useCreateFlowMutation,
  useDeleteFlowMutation,
  useUpdateFlowMutation,
} = flowEndpoints;

export default flowEndpoints;
