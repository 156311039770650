import { Autocomplete, Icon, InputBase, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Account } from "../../entities/account";
import { useAccount, useAccounts } from "../../redux/account/accountHooks";

/**
 * @param {accountAutocompleteProps} accountAutocompleteProps PriorityLevel object from API
 * @returns {ReactElement} Account Autocomplete
 */
export function AccountAutocomplete({
  accountID,
  onChange,
  variant,
  size,
  helperText,
  disabled,
  autoFocus,
}: accountAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const { accounts } = useAccounts();
  const { account } = useAccount(accountID);

  useEffect(() => {
    if (account) {
      setInputValue(account.name);
    }
  }, [account]);

  return (
    <Autocomplete
      value={account ?? null}
      onChange={(_e, newValue) => {
        onChange(newValue);
      }}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={(_e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      size={size}
      getOptionLabel={(option) => `${option.name}`}
      options={accounts ?? []}
      clearIcon={<Icon sx={{ fontSize: 15 }} className="fa-solid fa-xmark" />}
      renderInput={(params) =>
        variant === "inputBase" ? (
          <div ref={params.InputProps.ref}>
            <InputBase
              inputProps={{ ...params.inputProps }}
              fullWidth
              sx={{ fontSize: 14 }}
              placeholder="Select a account..."
              autoFocus={autoFocus}
            />
          </div>
        ) : (
          <TextField
            variant={variant}
            {...params}
            fullWidth
            sx={{ fontSize: 14 }}
            placeholder="Select a account..."
            autoFocus={autoFocus}
            label="Account"
            helperText={helperText}
          />
        )
      }
    />
  );
}

type accountAutocompleteProps = {
  accountID?: number;
  onChange: (account: Account | null) => void;
  variant?: "standard" | "filled" | "outlined" | "inputBase" | undefined;
  size?: "small" | "medium" | undefined;
  helperText?: string;
  disabled?: boolean;
  autoFocus?: boolean;
};
