import Quota_Entity from "./quota";
export default class Quota extends Quota_Entity {
  static fromList(quotasJSON: unknown): Array<Quota> {
    const quotas: Quota[] = [];
    if (quotasJSON)
      Array.isArray(quotasJSON) &&
        quotasJSON.forEach((quotaJSON) => {
          quotas.push(new Quota(quotaJSON));
        });
    return quotas;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
