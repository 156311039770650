/**
 * Account_Entity type guard.
 *
 * @param {any} accountJson Account object from API
 * @returns {boolean} Return true if type is Account_Entity
 */
function validator(accountJson: unknown): accountJson is Account_Entity {
  if (typeof accountJson === "object" && accountJson != null) {
    Account_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(accountJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Account_Entity {
  static requiredFields = ["id", "refID", "name", "createdAt", "updatedAt"];

  constructor(accountJson: unknown) {
    if (validator(accountJson)) {
      this.id = accountJson.id;
      this.refID = accountJson.refID;
      this.name = accountJson.name;
      this.createdAt = accountJson.createdAt;
      this.updatedAt = accountJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Account_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  refID: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}
