import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from "@reduxjs/toolkit";
import { SearchMetadata } from "@nerdjs/nerd-core";
import { budgetApi } from "../api/budgetApi";
import { Transaction_Entity } from "../../entities/transaction";
import { RootState } from "../store";

const transactionsAdapter = createEntityAdapter<Transaction_Entity>();
const transactionsInitialState = transactionsAdapter.getInitialState();

export const transactionEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getTransactions: build.query<
      EntityState<Transaction_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `transactions`,
          params: args,
        };
      },
      transformResponse: (responseData: Transaction_Entity[]) => {
        return transactionsAdapter.setAll(
          transactionsInitialState,
          responseData
        );
      },
      providesTags: [
        "transactions",
        "quotas",
        "reports",
        "reportItems",
        "reportRows",
        "scores",
      ],
    }),
    getTransaction: build.query<Transaction_Entity, number>({
      query: (id) => `transactions/${id}`,
      providesTags: (_result, _error, id) => [{ type: "transactions", id }],
    }),
    createTransaction: build.mutation<
      Transaction_Entity,
      Partial<Transaction_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `transactions`,
      }),
      invalidatesTags: ["transactions"],
    }),
    transactionSync: build.mutation<
      Transaction_Entity,
      Partial<Transaction_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `transactions/sync`,
      }),
      invalidatesTags: ["transactions"],
    }),
    updateTransaction: build.mutation<
      void,
      { id: number; body: Partial<Transaction_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `transactions/${args.id}`,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "transactions", id },
        "transactions",
      ],
    }),
    deleteTransaction: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `transactions/${id}`,
      }),
      invalidatesTags: ["transactions"],
    }),
    getTransactionSearchMetadata: build.query<SearchMetadata, void>({
      query: () => `transactions/searchMetadata`,
    }),
  }),
});

export const {
  useTransactionSyncMutation,
  useGetTransactionQuery,
  useGetTransactionsQuery,
  useGetTransactionSearchMetadataQuery,
  useLazyGetTransactionsQuery,
  useLazyGetTransactionQuery,
  useLazyGetTransactionSearchMetadataQuery,
  useCreateTransactionMutation,
  useDeleteTransactionMutation,
  useUpdateTransactionMutation,
} = transactionEndpoints;

export default transactionEndpoints;

export const getTransactionsSelectors = (
  params: Record<string, unknown> | void
) => {
  return transactionsAdapter.getSelectors<RootState>((state) => {
    return (
      createSelector(
        transactionEndpoints.endpoints.getTransactions.select(params),
        (transactionsResult) => {
          return transactionsResult.data;
        }
      )(state) ?? transactionsInitialState
    );
  });
};
