import Account_Entity from "./account";
export default class Account extends Account_Entity {
  static fromList(accountsJSON: unknown): Array<Account> {
    const accounts: Account[] = [];
    if (accountsJSON)
      Array.isArray(accountsJSON) &&
        accountsJSON.forEach((accountJSON) => {
          accounts.push(new Account(accountJSON));
        });
    return accounts;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
