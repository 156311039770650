import { useMemo } from "react";
import { ExpenseType } from "../../entities/expenseType";
import {
  useGetExpenseTypeQuery,
  useGetExpenseTypesQuery,
} from "./expenseTypeEndpoints";

export function useExpenseType(
  expenseTypeID: number | undefined,
  opt?: { skip?: boolean }
) {
  const expenseTypeQuery = useGetExpenseTypeQuery(expenseTypeID ?? 0, {
    skip: opt?.skip || (expenseTypeID ?? 0) <= 0,
  });
  const expenseTypeEntity = expenseTypeQuery.data;

  return useMemo(() => {
    const ret: typeof expenseTypeQuery & {
      expenseType?: ExpenseType | undefined;
    } = {
      ...expenseTypeQuery,
    };
    if (expenseTypeEntity) ret.expenseType = new ExpenseType(expenseTypeEntity);
    return ret;
  }, [expenseTypeEntity]);
}

export function useExpenseTypes() {
  const expenseTypeQuery = useGetExpenseTypesQuery();
  const expenseTypeEntitiesMap = expenseTypeQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof expenseTypeQuery & {
      expenseTypes?: ExpenseType[];
      expenseTypesMap?: { [id: number]: ExpenseType };
    } = {
      ...expenseTypeQuery,
      expenseTypes: [],
      expenseTypesMap: {},
    };
    if (expenseTypeEntitiesMap) {
      const expenseTypes = [];
      const expenseTypesMap = {};

      for (const key in expenseTypeEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(expenseTypeEntitiesMap, key)) {
          const c = expenseTypeEntitiesMap[key];
          const expenseType = new ExpenseType(c);
          expenseTypes.push(expenseType);
          expenseTypesMap[expenseType.id] = expenseType;
        }
      }
      ret.expenseTypes = expenseTypes;
      ret.expenseTypesMap = expenseTypesMap;
    }

    return ret;
  }, [expenseTypeEntitiesMap]);
}
