import { useMemo } from "react";
import { Quota } from "../../entities/quota";
import { useGetQuotaQuery, useGetQuotasQuery } from "./quotaEndpoints";

export function useQuota(quotaID: number | undefined) {
  const quotaQuery = useGetQuotaQuery(quotaID ?? 0, {
    skip: (quotaID ?? 0) <= 0,
  });
  const quotaEntity = quotaQuery.data;

  return useMemo(() => {
    const ret: typeof quotaQuery & { quota?: Quota | undefined } = {
      ...quotaQuery,
    };
    if (quotaEntity) ret.quota = new Quota(quotaEntity);
    return ret;
  }, [quotaEntity]);
}

export function useQuotas() {
  const quotaQuery = useGetQuotasQuery();
  const quotaEntitiesMap = quotaQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof quotaQuery & {
      quotas?: Quota[];
      quotasMap?: { [id: number]: Quota };
    } = {
      ...quotaQuery,
      quotas: [],
      quotasMap: {},
    };
    if (quotaEntitiesMap) {
      const quotas = [];
      const quotasMap = {};

      for (const key in quotaEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(quotaEntitiesMap, key)) {
          const c = quotaEntitiesMap[key];
          const quota = new Quota(c);
          quotas.push(quota);
          quotasMap[quota.id] = quota;
        }
      }
      ret.quotas = quotas;
      ret.quotasMap = quotasMap;
    }

    return ret;
  }, [quotaEntitiesMap]);
}
