import { AccountBox, PowerSettingsNew } from "@mui/icons-material";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSignOutMutation } from "@nerdjs/account-kit";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { StyledMenu } from "@nerdjs/nerd-ui";

/**
 * @component
 * @returns {React.ReactElement} UserDisplay component
 */
export function NerdUserMenu(): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const id = open ? "basic-menu" : undefined;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [signoutAction] = useSignOutMutation();

  /**
   * Disconnect user
   */
  function signout() {
    signoutAction();
  }

  return (
    <>
      {md ? (
        <Button
          size="small"
          onClick={handleClick}
          variant={"outlined"}
          startIcon={<AccountBox fontSize="small" />}
        >
          {t("global.my_account")}
        </Button>
      ) : (
        <Tooltip title={t("entities:app.entity")}>
          <IconButton color="primary" size="small" onClick={handleClick}>
            <AccountBox />
          </IconButton>
        </Tooltip>
      )}
      <StyledMenu
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <MenuList>
          <MenuItem onClick={signout}>
            <ListItemIcon>
              <PowerSettingsNew />
            </ListItemIcon>
            <ListItemText>{t("global.signout")}</ListItemText>
          </MenuItem>
        </MenuList>
      </StyledMenu>
    </>
  );
}
