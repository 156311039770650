import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Menu,
} from "@mui/icons-material";
import { useColorScheme } from "@mui/joy";
import {
  Backdrop,
  Button,
  CSSObject,
  Divider,
  IconButton,
  ListItemButton,
  styled,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { RouterConfig } from "../config/routerConfig";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

/**
 *
 * @component
 * @example
 * <MainDrawer/>
 * @returns {ReactElement | null} MainDrawer component
 */
export function MainDrawer(): ReactElement | null {
  const [backDropOpen, setBackDropOpen] = useState(false);
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"), { noSsr: true });
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const [userSideBarState, setUserSideBarState] = useState(
    isDownLg
      ? false
      : localStorage.getItem("sideBarOpen") === null ||
        localStorage.getItem("sideBarOpen") === "true"
      ? true
      : false
  );
  const location = useLocation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const { t } = useTranslation();

  const { mode } = useColorScheme();
  const isDark = mode === "dark";

  /**
   * Open or collapse the drawer.
   */
  function handleDrawerCollapse() {
    setUserSideBarState(!userSideBarState);
    if (isDownSm) {
      setBackDropOpen(true);
    } else {
      localStorage.setItem("sideBarOpen", `${!userSideBarState}`);
    }
  }

  useEffect(
    function () {
      setUserSideBarState(false);
      setBackDropOpen(false);
    },
    [isDownSm]
  );

  useEffect(
    function () {
      if (isDownLg) {
        setUserSideBarState(false);
      } else {
        setUserSideBarState(
          localStorage.getItem("sideBarOpen") === null ||
            localStorage.getItem("sideBarOpen") === "true"
            ? true
            : false
        );
      }
    },
    [isDownLg]
  );

  /**
   * Close the drawer when the user click on the backDrop
   */
  function handleBackDropClick() {
    setBackDropOpen(false);
    handleDrawerCollapse();
  }

  /**
   * Close drawer menu when click on menu item.
   */
  function handleClickOnMenuItem() {
    if (isDownSm) setUserSideBarState(false);
  }

  if (!currentUser) return null;

  return (
    <div
      style={{
        height: isDownSm ? "50px" : "inherit",
        backgroundColor: isDark ? "#fff" : "#121212",
        marginTop: "48px",
      }}
    >
      {isDownSm && (
        <Box>
          <IconButton onClick={handleDrawerCollapse} color={"primary"}>
            <Menu />
          </IconButton>
          {userSideBarState && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer - 1,
              }}
              open={backDropOpen}
              onClick={handleBackDropClick}
            />
          )}
        </Box>
      )}
      <CustomDrawer open={userSideBarState} variant="permanent">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            height: "100%",
            mt: 5,
          }}
        >
          <List sx={{ marginBottom: "auto", flex: 1, width: "100%" }}>
            <ListItem
              disablePadding
              selected={pathnames.indexOf(RouterConfig.reports) >= 0}
            >
              <ListItemButton
                component={Link}
                to={RouterConfig.reports}
                onClick={handleClickOnMenuItem}
              >
                <ListItemIcon>
                  <i className="fa-duotone fa-chart-line"></i>
                </ListItemIcon>
                <ListItemText primary="Reports" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              selected={pathnames.indexOf(RouterConfig.scores) >= 0}
            >
              <ListItemButton
                component={Link}
                to={RouterConfig.scores}
                onClick={handleClickOnMenuItem}
              >
                <ListItemIcon>
                  <i className="fa-duotone fa-bullseye-arrow"></i>
                </ListItemIcon>
                <ListItemText primary="Scores" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              selected={pathnames.indexOf(RouterConfig.sankeys) >= 0}
            >
              <ListItemButton
                component={Link}
                to={RouterConfig.sankeys}
                onClick={handleClickOnMenuItem}
              >
                <ListItemIcon>
                  <i className="fa-duotone fa-diagram-sankey"></i>
                </ListItemIcon>
                <ListItemText primary="Sankeys" />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem
              disablePadding
              selected={pathnames.indexOf(RouterConfig.transactions) >= 0}
            >
              <ListItemButton
                component={Link}
                to={RouterConfig.transactions}
                onClick={handleClickOnMenuItem}
              >
                <ListItemIcon>
                  <i className="fa-duotone fa-receipt"></i>
                </ListItemIcon>
                <ListItemText primary="Transactions" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              selected={pathnames.indexOf(RouterConfig.transactionGroups) >= 0}
            >
              <ListItemButton
                component={Link}
                to={RouterConfig.transactionGroups}
                onClick={handleClickOnMenuItem}
              >
                <ListItemIcon>
                  <i className="fa-duotone fa-square-dollar"></i>
                </ListItemIcon>
                <ListItemText primary="Transaction Groups" />
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              selected={pathnames.indexOf(RouterConfig.quotas) >= 0}
            >
              <ListItemButton
                component={Link}
                to={RouterConfig.quotas}
                onClick={handleClickOnMenuItem}
              >
                <ListItemIcon>
                  <i className="fa-duotone fa-arrow-up-to-line"></i>
                </ListItemIcon>
                <ListItemText primary="Quotas" />
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem
              disablePadding
              selected={pathnames.indexOf(RouterConfig.accounts) >= 0}
            >
              <ListItemButton
                component={Link}
                to={RouterConfig.accounts}
                onClick={handleClickOnMenuItem}
              >
                <ListItemIcon>
                  <i className="fa-duotone fa-building-columns"></i>
                </ListItemIcon>
                <ListItemText primary="Accounts" />
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              selected={pathnames.indexOf(RouterConfig.vendors) >= 0}
            >
              <ListItemButton
                component={Link}
                to={RouterConfig.vendors}
                onClick={handleClickOnMenuItem}
              >
                <ListItemIcon>
                  <i className="fa-duotone fa-shop"></i>
                </ListItemIcon>
                <ListItemText primary="Vendors" />
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              selected={pathnames.indexOf(RouterConfig.expenseTypes) >= 0}
            >
              <ListItemButton
                component={Link}
                to={RouterConfig.expenseTypes}
                onClick={handleClickOnMenuItem}
              >
                <ListItemIcon>
                  <i className="fa-duotone fa-tags"></i>
                </ListItemIcon>
                <ListItemText primary="Expense Types" />
              </ListItemButton>
            </ListItem>
          </List>
          {userSideBarState ? (
            <Button
              variant="text"
              fullWidth
              onClick={handleDrawerCollapse}
              sx={{
                justifySelf: "flex-end",
                alignSelf: "center",
                paddingBottom: "18px",
              }}
              startIcon={<KeyboardDoubleArrowLeft />}
            >
              {t("global.collapse")}
            </Button>
          ) : (
            <IconButton
              aria-label={t("global.open_sidebar")}
              color="primary"
              sx={{
                justifySelf: "flex-end",
                alignSelf: "center",
                marginBottom: "10px",
              }}
              onClick={handleDrawerCollapse}
            >
              <KeyboardDoubleArrowRight />
            </IconButton>
          )}
        </Box>
      </CustomDrawer>
    </div>
  );
}
