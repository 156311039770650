import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button, Chip, Typography } from "@mui/joy";
import { Paper, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useConfirm } from "material-ui-confirm";
import { Quota_Entity } from "../../entities/quota";
import {
  currencyFormatter,
  getterDivider,
  setterDivider,
} from "../../helpers/datagrid";
import {
  useCreateQuotaMutation,
  useDeleteQuotaMutation,
  useUpdateQuotaMutation,
} from "../../redux/quota/quotaEndpoints";
import { useQuota, useQuotas } from "../../redux/quota/quotaHooks";
import { useTransactionGroup } from "../../redux/transactionGroup/transactionGroupHooks";
import { DateTime } from "luxon";
import { useExpenseType } from "../../redux/expenseType/expenseTypeHooks";
import { useAccount } from "../../redux/account/accountHooks";
import { useVendor } from "../../redux/vendor/vendorHooks";

/**
 * Quota
 *
 * @returns {null} Quota
 */
export default function QuotasDataGrid() {
  const { quotas, isLoading } = useQuotas();
  const [updateQuota] = useUpdateQuotaMutation();
  const [deleteQuota] = useDeleteQuotaMutation();

  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  const onQuotaUpdate = async (
    _newQuota: Quota_Entity,
    _oldQuota: Quota_Entity
  ) => {
    return new Promise<Quota_Entity>((resolve) => {
      updateQuota({
        id: _newQuota.id,
        body: _newQuota,
      });
      setTimeout(() => {
        resolve({ ..._newQuota });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1, display: "flex" }}>
      <DataGridPro
        density="compact"
        apiRef={apiRef}
        loading={isLoading}
        processRowUpdate={onQuotaUpdate}
        rows={quotas ?? []}
        columns={[
          {
            field: "id",
            headerName: "ID",
            editable: false,
            type: "number",
            width: 40,
          },
          {
            field: "name",
            headerName: "Name",
            editable: true,
            type: "string",
            width: 200,
          },
          {
            field: "refID",
            headerName: "RefID",
            editable: true,
            type: "number",
            width: 100,
            renderCell: (i) => (
              <TransactionGroupLabel refType={i.row.refType} id={i.row.refID} />
            ),
          },
          {
            field: "refType",
            headerName: "RefType",
            editable: true,
            type: "string",
            width: 200,
          },
          {
            field: "amount",
            headerName: "Amount",
            editable: true,
            type: "number",
            width: 150,
            valueGetter: getterDivider("amount", 100),
            valueSetter: setterDivider("amount", 100),
            valueFormatter: (p) => currencyFormatter.format(p.value),
          },
          {
            field: "frequency",
            headerName: "Frequency",
            editable: true,
            type: "string",
            width: 100,
          },
          {
            field: "usage",
            headerName: "Usage",
            editable: false,
            type: "number",
            width: 120,
            renderCell: (i) => <QuotaLabel id={i.row.id} />,
          },
          {
            field: "score",
            headerName: "Score",
            editable: false,
            type: "number",
            width: 150,
            renderCell: (i) => <QuotaScore id={i.row.id} />,
          },
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (p) => [
              <GridActionsCellItem
                key="delete"
                icon={<DeleteIcon />}
                label={`Delete`}
                onClick={() => {
                  confirm({
                    description: `You are about to delete a quota`,
                  })
                    .then(() => {
                      deleteQuota(p.row.id);
                    })
                    .catch(() => {
                      /* ... */
                    });
                }}
                color="inherit"
              />,
            ],
          },
        ]}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: QuotaToolbar,
        }}
        initialState={{
          pinnedColumns: { left: ["id"], right: ["actions"] },
        }}
      />
    </Paper>
  );
}

function QuotaToolbar() {
  const [createQuota] = useCreateQuotaMutation();

  const handleNewQuota = () => {
    const newQuota = {
      id: -1,
      name: "",
      createdAt: "",
      updatedAt: "",
    };
    createQuota(newQuota);
  };

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, alignItems: "start", gap: 1 }}
    >
      <Button variant="soft" onClick={handleNewQuota}>
        + New
      </Button>
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}

export function TransactionGroupLabel({
  id,
  refType,
}: {
  id: number;
  refType: string;
}) {
  const { transactionGroup } = useTransactionGroup(id, {
    skip: refType !== "transactionGroup",
  });
  const { expenseType } = useExpenseType(id, {
    skip: refType !== "expenseType",
  });
  const { account } = useAccount(id, {
    skip: refType !== "account",
  });
  const { vendor } = useVendor(id, {
    skip: refType !== "vendor",
  });

  let label = "";
  if (refType === "expenseType") label = expenseType?.name ?? "";
  if (refType === "transactionGroup") label = transactionGroup?.name ?? "";
  if (refType === "account") label = account?.name ?? "";
  if (refType === "vendor") label = vendor?.name ?? "";

  return <Typography level="body2">{label}</Typography>;
}

function QuotaLabel({ id }: { id: number }) {
  const { quota } = useQuota(id);

  function getDaysInMonth(year: number, month: number) {
    return DateTime.local(year, month).daysInMonth;
  }

  function budgetStatus(monthlyBudget: number, amountSpent: number) {
    // Get the current date and time using Luxon
    const today = DateTime.local();
    const currentYear = today.year;
    const currentMonth = today.month;
    const currentDay = today.day;

    // Calculate the actual number of days in the current month
    const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

    // Calculate daily budget
    const dailyBudget = monthlyBudget / daysInCurrentMonth;

    // Calculate the number of days that the amount spent represents
    const spentDays = amountSpent / dailyBudget;

    // Calculate the difference between spent days and current day
    const dayDifference = spentDays - currentDay;

    return dayDifference;
  }

  const amountSpent = (quota?.amount ?? 0) / 100 - (quota?.usage ?? 0) / 100;
  const days = Math.round(budgetStatus(quota?.amount ?? 0, quota?.usage ?? 0));
  return (
    <Box sx={{ textAlign: "right" }}>
      <Tooltip title={`Remaining: ${currencyFormatter.format(amountSpent)}`}>
        <Typography
          level="body2"
          color={
            (quota?.usage ?? 0) > (quota?.amount ?? 0) ? "danger" : "success"
          }
        >
          {currencyFormatter.format((quota?.usage ?? 0) / 100)}
        </Typography>
      </Tooltip>
      {quota?.frequency === "monthly" ? (
        <Typography level="body4" color={days < 0 ? "success" : "warning"}>
          {days < 0 ? `${Math.abs(days)} days ahead` : `${days} days behind`}
        </Typography>
      ) : (
        []
      )}
    </Box>
  );
}

export function QuotaScore({ id }: { id: number }) {
  const { quota } = useQuota(id);
  const score = quota?.score ?? 0;
  let color:
    | "primary"
    | "neutral"
    | "danger"
    | "info"
    | "success"
    | "warning"
    | undefined = "success";
  if (score < 800) color = "warning";
  if (score < 600) color = "danger";

  return (
    <Chip variant="soft" size="sm" color={color}>
      {score}
    </Chip>
  );
}
