import { Box } from "@mui/material";
import SankeysDataGrid from "../hooks/sankeys/sankeysDatagrid";
import { Outlet, useParams } from "react-router-dom";

/**
 *
 * @returns {ReactElement} SankeyRoute page
 */
export function SankeysRoute() {
  const params = useParams();
  const reportID = params.sankeyID ? parseInt(params.sankeyID) : undefined;

  if (reportID) return <Outlet />;

  return (
    <Box sx={{ p: 1, display: "flex", flex: 1 }}>
      <SankeysDataGrid />
    </Box>
  );
}
