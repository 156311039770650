import { Input, Option, Select } from "@mui/joy";
import { NerdAdvancedSearch } from "@nerdjs/nerd-ui";
import { AdvancedSearchDefinition } from "@nerdjs/nerd-ui/dist/nerdAdvancedSearch/types";
import moment from "moment";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetTransactionSearchMetadataQuery,
  useLazyGetTransactionsQuery,
} from "../../redux/transaction/transactionEndpoints";
import { AccountAutocomplete } from "../account/accountAutocomplete";
import { ExpenseTypeAutocomplete } from "../expenseType/expenseTypeAutocomplete";
import { VendorAutocomplete } from "../vendor/vendorAutocomplete";

/**
 *
 * @returns {ReactElement} Search Transaction
 */
export function TransactionsSearch() {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [getTransactions] = useLazyGetTransactionsQuery();
  const { data } = useGetTransactionSearchMetadataQuery();

  const definition: AdvancedSearchDefinition[] = [
    {
      id: "refID",
      name: "transactions.refID",
      label: "Reference ID",
      type: "int",
      faIcon: "fa-solid fa-hashtag",
    },
    {
      id: "kind",
      name: "transactions.kind",
      label: "Kind",
      type: "int",
      faIcon: "fa-solid fa-tag",
      renderInput: (value, onChange) => (
        <TransactionKindSelect
          value={value as number}
          onChange={(e) => onChange(e ?? "")}
        />
      ),
    },
    {
      id: "vendorID",
      name: "transactions.vendorID",
      label: "Vendor",
      type: "int",
      faIcon: "fa-sharp fa-solid fa-store",
      renderInput: (value, onChange) => (
        <VendorAutocomplete
          vendorID={value as number}
          onChange={(e) => onChange(e?.id)}
          autoFocus
          variant="inputBase"
          size="small"
        />
      ),
    },
    {
      id: "accountID",
      name: "transactions.accountID",
      label: "Account",
      type: "int",
      faIcon: "fa-solid fa-building-columns",
      renderInput: (value, onChange) => (
        <AccountAutocomplete
          accountID={value as number}
          onChange={(e) => onChange(e?.id)}
          autoFocus
          variant="inputBase"
          size="small"
        />
      ),
    },
    {
      id: "expenseTypeID",
      name: "transactions.expenseTypeID",
      label: "Expense Type",
      type: "string",
      faIcon: "fa-solid fa-tags",
      renderInput: (value, onChange) => (
        <ExpenseTypeAutocomplete
          expenseTypeID={value as number}
          onChange={(e) => onChange(e?.id)}
          autoFocus
          variant="inputBase"
          size="small"
        />
      ),
    },
    {
      id: "date",
      name: "transactions.date",
      label: "Date",
      type: "date",
      faIcon: "fa-regular fa-calendar-days",
    },
  ];

  return (
    <NerdAdvancedSearch
      definitions={definition}
      open={open}
      searchMetadata={data}
      setOpen={(o) => setOpen(o)}
      onNewSearchPreset={() => setOpen(false)}
      onSearch={(q) => {
        if (q) {
          getTransactions(q, false);
        } else {
          getTransactions(
            {
              offset: "0",
              limit: searchParams.get("limit") ?? "50",
            },
            false
          );
        }
      }}
    />
  );
}

function TransactionKindSelect({
  value,
  onChange,
}: {
  value: number;
  onChange: (i: number | null) => void;
}) {
  return (
    <Select
      onChange={(e, v) => onChange(v)}
      value={value}
      placeholder="Transaction type"
    >
      <Option value={"credit"}>Credit</Option>
      <Option value={"debit"}>Debit</Option>
    </Select>
  );
}

function SearchDatePicker({
  value,
  onChange,
}: {
  value: string;
  onChange: (i: string | null) => void;
}) {
  return (
    <Input
      size="sm"
      type="date"
      onChange={(e) =>
        onChange(moment(e.target.value).format("YYYY-MM-DD 00:00:00"))
      }
      value={moment(value).utc().format("YYYY-MM-DD")}
    />
  );
}
