/**
 * Vendor_Entity type guard.
 *
 * @param {any} vendorJson Vendor object from API
 * @returns {boolean} Return true if type is Vendor_Entity
 */
function validator(vendorJson: unknown): vendorJson is Vendor_Entity {
  if (typeof vendorJson === "object" && vendorJson != null) {
    Vendor_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(vendorJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Vendor_Entity {
  static requiredFields = ["id", "refID", "name", "createdAt", "updatedAt"];

  constructor(vendorJson: unknown) {
    if (validator(vendorJson)) {
      this.id = vendorJson.id;
      this.refID = vendorJson.refID;
      this.name = vendorJson.name;
      this.createdAt = vendorJson.createdAt;
      this.updatedAt = vendorJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Vendor_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  refID: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}
