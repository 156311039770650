import {
  Box,
  CircularProgress,
  CssBaseline,
  Dialog,
  Grid,
  Slide,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { initNetworkAction } from "@nerdjs/nerd-network";
import { clearNotification, NerdNotifier, NerdAppBar } from "@nerdjs/nerd-ui";
import { forwardRef, ReactElement, useEffect } from "react";

import { CssVarsProvider, IconButton } from "@mui/joy";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";
import { appBarConfig } from "../config/appBarConfig";
import { useAppDispatch, useAppSelector } from "../hooks";
import { MainDrawer } from "../mainDrawer/mainDrawer";
import styles from "./style.module.scss";
import { getTheme } from "../config/themeConfig";
import {
  useGetCurrentUserQuery,
  useSignOutMutation,
} from "@nerdjs/account-kit";
import { RouterConfig } from "../config/routerConfig";
import { formOpenSelector } from "../../redux/appStatus/appStatusSelector";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { TransitionProps } from "@mui/material/transitions";
import TransactionsDataGrid from "../transactions/transactionsDataGrid";
import { useSearchParams } from "react-router-dom";
import { useTransactionGroups } from "../../redux/transactionGroup/transactionGroupHooks";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  const dispatch = useAppDispatch();
  const muiTheme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const isDownSm = useMediaQuery(muiTheme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const { isSuccess } = useSignOutMutation()[1];
  const transactionDialogOpen = useAppSelector(
    formOpenSelector("transactions")
  );

  useEffect(() => {
    dispatch(initNetworkAction(AppConfig.api));
  }, []);

  const theme = getTheme();
  const { isFetching } = useGetCurrentUserQuery();

  useEffect(() => {
    //If signout success, refresh window to reset redux state
    if (isSuccess) {
      window.location.replace(RouterConfig.root);
    }
  }, [isSuccess]);

  return (
    <CssVarsProvider theme={theme}>
      <NotifierWrapper>
        <Box
          sx={{
            backgroundImage: `url("${AppConfig.app.rootBackground}") `,
            display: "flex",
            flex: 1,
            flexDirection: isDownSm ? "column" : "row",
          }}
        >
          <CssBaseline />
          {isFetching ? (
            <Grid className={styles.circularProgressContainer} container>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              <NerdAppBar {...appBarConfig(theme)} />
              <MainDrawer />
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  display: "flex",
                }}
              >
                <AppRouter />
              </Box>
            </>
          )}
        </Box>
      </NotifierWrapper>
      {transactionDialogOpen ? (
        <Dialog
          TransitionComponent={Transition}
          open={transactionDialogOpen}
          fullScreen
          onClose={() => {
            dispatch(setFormOpen(false, "transactions"));
            setSearchParams({ filters: [] });
          }}
        >
          <Box
            sx={{
              p: 1,
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <Box sx={{ textAlign: "right" }}>
              <IconButton
                color="neutral"
                variant="plain"
                onClick={() => {
                  dispatch(setFormOpen(false, "transactions"));
                  setSearchParams({ filters: [] });
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </IconButton>
            </Box>
            <TransactionsDataGrid hideQuickbooksImport />
          </Box>
        </Dialog>
      ) : (
        []
      )}
    </CssVarsProvider>
  );
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NotifierWrapper = ({ children }: { children: ReactElement }) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector((s) => s.notification);
  return (
    notification && (
      <NerdNotifier
        {...notification}
        onClose={() => {
          dispatch(clearNotification());
          notification.onClose?.();
        }}
      >
        {children}
      </NerdNotifier>
    )
  );
};
