import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button } from "@mui/joy";
import { Paper } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useConfirm } from "material-ui-confirm";
import { ExpenseType_Entity } from "../../entities/expenseType";
import {
  useCreateExpenseTypeMutation,
  useDeleteExpenseTypeMutation,
  useUpdateExpenseTypeMutation,
} from "../../redux/expenseType/expenseTypeEndpoints";
import { useExpenseTypes } from "../../redux/expenseType/expenseTypeHooks";

/**
 * ExpenseType
 *
 * @returns {null} ExpenseType
 */
export default function ExpenseTypesDataGrid() {
  const { expenseTypes, isLoading } = useExpenseTypes();
  const [updateExpenseType] = useUpdateExpenseTypeMutation();
  const [deleteExpenseType] = useDeleteExpenseTypeMutation();

  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  const onExpenseTypeUpdate = async (
    _newExpenseType: ExpenseType_Entity,
    _oldExpenseType: ExpenseType_Entity
  ) => {
    return new Promise<ExpenseType_Entity>((resolve) => {
      updateExpenseType({
        id: _newExpenseType.id,
        body: _newExpenseType,
      });
      setTimeout(() => {
        resolve({ ..._newExpenseType });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1, display: "flex" }}>
      <DataGridPro
        density="compact"
        apiRef={apiRef}
        loading={isLoading}
        processRowUpdate={onExpenseTypeUpdate}
        rows={expenseTypes ?? []}
        columns={[
          {
            field: "id",
            headerName: "ID",
            editable: false,
            type: "number",
            width: 40,
          },
          {
            field: "name",
            headerName: "Name",
            editable: true,
            type: "string",
            flex: 1,
          },
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (p) => [
              <GridActionsCellItem
                key="delete"
                icon={<DeleteIcon />}
                label={`Delete`}
                onClick={() => {
                  confirm({
                    description: `You are about to delete a expenseType`,
                  })
                    .then(() => {
                      deleteExpenseType(p.row.id);
                    })
                    .catch(() => {
                      /* ... */
                    });
                }}
                color="inherit"
              />,
            ],
          },
        ]}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: ExpenseTypeToolbar,
        }}
        initialState={{
          pinnedColumns: { left: ["id"], right: ["actions"] },
        }}
      />
    </Paper>
  );
}

function ExpenseTypeToolbar() {
  const [createExpenseType] = useCreateExpenseTypeMutation();

  const handleNewExpenseType = () => {
    const newExpenseType = {
      id: -1,
      name: "",
      createdAt: "",
      updatedAt: "",
    };
    createExpenseType(newExpenseType);
  };

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, alignItems: "start", gap: 1 }}
    >
      <Button variant="soft" onClick={handleNewExpenseType}>
        + New
      </Button>
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}
