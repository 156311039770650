import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { Vendor_Entity } from "../../entities/vendor";
import { budgetApi } from "../api/budgetApi";

const vendorsAdapter = createEntityAdapter<Vendor_Entity>();
const initialState = vendorsAdapter.getInitialState();

export const vendorEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getVendors: build.query<EntityState<Vendor_Entity>, void>({
      query: () => `vendors`,
      transformResponse: (responseData: Vendor_Entity[]) => {
        return vendorsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["vendors"],
    }),
    getVendor: build.query<Vendor_Entity, number>({
      query: (id) => `vendors/${id}`,
      providesTags: (_result, _error, id) => [{ type: "vendors", id }],
    }),
    createVendor: build.mutation<Vendor_Entity, Partial<Vendor_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `vendors`,
      }),
      invalidatesTags: ["vendors"],
    }),
    updateVendor: build.mutation<
      void,
      { id: number; body: Partial<Vendor_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `vendors/${args.id}`,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "vendors", id },
        "vendors",
      ],
    }),
    deleteVendor: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `vendors/${id}`,
      }),
      invalidatesTags: ["vendors"],
    }),
  }),
});

export const {
  useGetVendorQuery,
  useGetVendorsQuery,
  useCreateVendorMutation,
  useDeleteVendorMutation,
  useUpdateVendorMutation,
} = vendorEndpoints;

export default vendorEndpoints;

export const selectVendorsResult =
  vendorEndpoints.endpoints.getVendors.select();

const selectVendorsData = createSelector(
  selectVendorsResult,
  (vendorsResult) => vendorsResult.data
);

const store = configureStore({
  reducer: {
    [budgetApi.reducerPath]: budgetApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllVendors, selectById: selectVendorById } =
  vendorsAdapter.getSelectors<RootState>(
    (state) => selectVendorsData(state) ?? initialState
  );
