import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { Score_Entity } from "../../entities/score";
import { budgetApi } from "../api/budgetApi";

const scoresAdapter = createEntityAdapter<Score_Entity>();
const initialState = scoresAdapter.getInitialState();

export const scoreEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getScores: build.query<EntityState<Score_Entity>, void>({
      query: () => `scores`,
      transformResponse: (responseData: Score_Entity[]) => {
        return scoresAdapter.setAll(initialState, responseData);
      },
      providesTags: ["scores"],
    }),
    getScore: build.query<Score_Entity, number>({
      query: (id) => `scores/${id}`,
      providesTags: (_result, _error, id) => [{ type: "scores", id }],
    }),
    createScore: build.mutation<Score_Entity, Partial<Score_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `scores`,
      }),
      invalidatesTags: ["scores"],
    }),
    updateScore: build.mutation<
      void,
      { id: number; body: Partial<Score_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `scores/${args.id}`,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "scores", id },
        "scores",
      ],
    }),
    deleteScore: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `scores/${id}`,
      }),
      invalidatesTags: ["scores"],
    }),
  }),
});

export const {
  useGetScoreQuery,
  useGetScoresQuery,
  useCreateScoreMutation,
  useDeleteScoreMutation,
  useUpdateScoreMutation,
} = scoreEndpoints;

export default scoreEndpoints;

export const selectScoresResult = scoreEndpoints.endpoints.getScores.select();

const selectScoresData = createSelector(
  selectScoresResult,
  (scoresResult) => scoresResult.data
);

const store = configureStore({
  reducer: {
    [budgetApi.reducerPath]: budgetApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllScores, selectById: selectScoreById } =
  scoresAdapter.getSelectors<RootState>(
    (state) => selectScoresData(state) ?? initialState
  );
