import { Quota_Entity } from "../quota";
import { ReportGroup_Entity } from "../reportGroup";
import { ReportRow_Entity } from "../reportRow";

/**
 * Report_Entity type guard.
 *
 * @param {any} reportJson Report object from API
 * @returns {boolean} Return true if type is Report_Entity
 */
function validator(reportJson: unknown): reportJson is Report_Entity {
  if (typeof reportJson === "object" && reportJson != null) {
    Report_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(reportJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Report_Entity {
  static requiredFields = [
    "id",
    "name",
    "dateFrom",
    "frequency",
    "rows",
    "groups",
    "quotas",
    "createdAt",
    "updatedAt",
  ];

  constructor(reportJson: unknown) {
    if (validator(reportJson)) {
      this.id = reportJson.id;
      this.name = reportJson.name;
      this.dateFrom = reportJson.dateFrom;
      this.frequency = reportJson.frequency;
      this.rows = reportJson.rows;
      this.groups = reportJson.groups;
      this.quotas = reportJson.quotas;
      this.createdAt = reportJson.createdAt;
      this.updatedAt = reportJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Report_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  dateFrom: string;
  frequency: string;
  rows: Array<ReportRow_Entity>;
  groups: Array<ReportGroup_Entity>;
  quotas: Array<Quota_Entity>;
  createdAt: string;
  updatedAt: string;
}
