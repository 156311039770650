import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { Report_Entity } from "../../entities/report";
import { budgetApi } from "../api/budgetApi";

const reportsAdapter = createEntityAdapter<Report_Entity>();
const initialState = reportsAdapter.getInitialState();

export const reportEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getReports: build.query<EntityState<Report_Entity>, void>({
      query: () => `reports`,
      transformResponse: (responseData: Report_Entity[]) => {
        return reportsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["reports"],
    }),
    getReport: build.query<Report_Entity, number>({
      query: (id) => `reports/${id}/calculate`,
      providesTags: (_result, _error, id) => [{ type: "reports", id }],
    }),
    createReport: build.mutation<Report_Entity, Partial<Report_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `reports`,
      }),
      invalidatesTags: ["reports"],
    }),
    updateReport: build.mutation<
      void,
      { id: number; body: Partial<Report_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `reports/${args.id}`,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "reports", id },
        "reports",
      ],
    }),
    deleteReport: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `reports/${id}`,
      }),
      invalidatesTags: ["reports"],
    }),
  }),
});

export const {
  useGetReportQuery,
  useGetReportsQuery,
  useCreateReportMutation,
  useDeleteReportMutation,
  useUpdateReportMutation,
  useLazyGetReportQuery,
} = reportEndpoints;

export default reportEndpoints;

export const selectReportsResult =
  reportEndpoints.endpoints.getReports.select();

const selectReportsData = createSelector(
  selectReportsResult,
  (reportsResult) => reportsResult.data
);

const store = configureStore({
  reducer: {
    [budgetApi.reducerPath]: budgetApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllReports, selectById: selectReportById } =
  reportsAdapter.getSelectors<RootState>(
    (state) => selectReportsData(state) ?? initialState
  );
