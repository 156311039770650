import { useMemo } from "react";
import { Report } from "../../entities/report";
import { useGetReportQuery, useGetReportsQuery } from "./reportEndpoints";

export function useReport(reportID: number | undefined) {
  const reportQuery = useGetReportQuery(reportID ?? 0, {
    skip: (reportID ?? 0) <= 0,
  });
  const reportEntity = reportQuery.data;

  return useMemo(() => {
    const ret: typeof reportQuery & { report?: Report | undefined } = {
      ...reportQuery,
    };
    if (reportEntity) ret.report = new Report(reportEntity);
    return ret;
  }, [reportEntity]);
}

export function useReports() {
  const reportQuery = useGetReportsQuery();
  const reportEntitiesMap = reportQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof reportQuery & {
      reports?: Report[];
      reportsMap?: { [id: number]: Report };
    } = {
      ...reportQuery,
      reports: [],
      reportsMap: {},
    };
    if (reportEntitiesMap) {
      const reports = [];
      const reportsMap = {};

      for (const key in reportEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(reportEntitiesMap, key)) {
          const c = reportEntitiesMap[key];
          const report = new Report(c);
          reports.push(report);
          reportsMap[report.id] = report;
        }
      }
      ret.reports = reports;
      ret.reportsMap = reportsMap;
    }

    return ret;
  }, [reportEntitiesMap]);
}
