import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { ExpenseType_Entity } from "../../entities/expenseType";
import { budgetApi } from "../api/budgetApi";

const expenseTypesAdapter = createEntityAdapter<ExpenseType_Entity>();
const initialState = expenseTypesAdapter.getInitialState();

export const expenseTypeEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getExpenseTypes: build.query<EntityState<ExpenseType_Entity>, void>({
      query: () => `expenseTypes`,
      transformResponse: (responseData: ExpenseType_Entity[]) => {
        return expenseTypesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["expenseTypes"],
    }),
    getExpenseType: build.query<ExpenseType_Entity, number>({
      query: (id) => `expenseTypes/${id}`,
      providesTags: (_result, _error, id) => [{ type: "expenseTypes", id }],
    }),
    createExpenseType: build.mutation<
      ExpenseType_Entity,
      Partial<ExpenseType_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `expenseTypes`,
      }),
      invalidatesTags: ["expenseTypes"],
    }),
    updateExpenseType: build.mutation<
      void,
      { id: number; body: Partial<ExpenseType_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `expenseTypes/${args.id}`,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "expenseTypes", id },
        "expenseTypes",
      ],
    }),
    deleteExpenseType: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `expenseTypes/${id}`,
      }),
      invalidatesTags: ["expenseTypes"],
    }),
  }),
});

export const {
  useGetExpenseTypeQuery,
  useGetExpenseTypesQuery,
  useCreateExpenseTypeMutation,
  useDeleteExpenseTypeMutation,
  useUpdateExpenseTypeMutation,
} = expenseTypeEndpoints;

export default expenseTypeEndpoints;

export const selectExpenseTypesResult =
  expenseTypeEndpoints.endpoints.getExpenseTypes.select();

const selectExpenseTypesData = createSelector(
  selectExpenseTypesResult,
  (expenseTypesResult) => expenseTypesResult.data
);

const store = configureStore({
  reducer: {
    [budgetApi.reducerPath]: budgetApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const {
  selectAll: selectAllExpenseTypes,
  selectById: selectExpenseTypeById,
} = expenseTypesAdapter.getSelectors<RootState>(
  (state) => selectExpenseTypesData(state) ?? initialState
);
