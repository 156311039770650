/**
 * Quota_Entity type guard.
 *
 * @param {any} quotaJson Quota object from API
 * @returns {boolean} Return true if type is Quota_Entity
 */
function validator(quotaJson: unknown): quotaJson is Quota_Entity {
  if (typeof quotaJson === "object" && quotaJson != null) {
    Quota_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(quotaJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Quota_Entity {
  static requiredFields = [
    "id",
    "name",
    "refID",
    "refType",
    "amount",
    "frequency",
    "usage",
    "score",
    "createdAt",
    "updatedAt",
  ];

  constructor(quotaJson: unknown) {
    if (validator(quotaJson)) {
      this.id = quotaJson.id;
      this.name = quotaJson.name;
      this.refID = quotaJson.refID;
      this.refType = quotaJson.refType;
      this.amount = quotaJson.amount;
      this.frequency = quotaJson.frequency;
      this.usage = quotaJson.usage;
      this.score = quotaJson.score;
      this.createdAt = quotaJson.createdAt;
      this.updatedAt = quotaJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Quota_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  refID: number;
  refType: string;
  amount: number;
  frequency: string;
  usage: number;
  score: number;
  createdAt: string;
  updatedAt: string;
}
