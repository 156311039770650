import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  Breadcrumbs,
  Button,
  Card,
  Input,
  Link,
  Option,
  Select,
  Tab,
  TabList,
  Tabs,
  Typography,
} from "@mui/joy";
import { Divider, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridColumns,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Report_Entity } from "../entities/report";
import { ReportGroup, ReportGroup_Entity } from "../entities/reportGroup";
import {
  currencyFormatter,
  getterDivider,
  setterDivider,
} from "../helpers/datagrid";
import { RouterConfig } from "../hooks/config/routerConfig";
import { setFormOpen } from "../redux/appStatus/appStatusActions";
import { useUpdateReportMutation } from "../redux/report/reportEndpoints";
import { useReport } from "../redux/report/reportHooks";
import {
  useCreateReportGroupMutation,
  useDeleteReportGroupMutation,
  useUpdateReportGroupMutation,
} from "../redux/reportGroup/reportGroupEndpoints";
import { useTransactionGroups } from "../redux/transactionGroup/transactionGroupHooks";
import { TransactionGroupLabel } from "../hooks/quotas/quotasDataGrid";

export default function ReportRoute(): ReactElement {
  const params = useParams();
  const { transactionGroupsMap } = useTransactionGroups();
  const [_searchParams, setSearchParams] = useSearchParams();
  const reportID = params?.reportID ? parseInt(params?.reportID) : undefined;
  const { report } = useReport(reportID);
  const groups = report?.groups;
  const [_report, setReport] = useState<Partial<Report_Entity> | null>();
  const [chartType, setCharType] = useState<"bar" | "line">("bar");
  const { rows, columns } = useReportResultsForDataGrid();
  const data = useReportResultsForChart(chartType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (report?.id) setReport(report);
  }, [report]);

  const [updateReport, { isLoading }] = useUpdateReportMutation();
  const [updateReportGroup] = useUpdateReportGroupMutation();
  const [deleteReportGroup] = useDeleteReportGroupMutation();
  const confirm = useConfirm();

  const save = () => {
    if (report?.id && _report)
      updateReport({
        id: report.id,
        body: { ..._report },
      });
  };

  const onReportGroupUpdate = async (
    _newReportGroup: ReportGroup_Entity,
    _oldReportGroup: ReportGroup_Entity
  ) => {
    return new Promise<ReportGroup_Entity>((resolve) => {
      updateReportGroup({
        id: _newReportGroup.id,
        body: _newReportGroup,
      });
      setTimeout(() => {
        resolve({ ..._newReportGroup });
      }, 50);
    });
  };

  const onCellClick = (p: GridCellParams) => {
    const cell = p.row[p.field];
    const group = groups?.find((g) => g.id === cell.groupID);
    const date = p.row.date;
    const frequency = report?.frequency ?? "day";
    const frequencyMap = {
      daily: "day",
      weekly: "isoWeek",
      monthly: "month",
      yearly: "year",
    };

    const dateFrom = moment(date)
      .startOf(frequencyMap[frequency])
      .format("YYYY-MM-DD");
    const dateTo = moment(date)
      .endOf(frequencyMap[frequency])
      .format("YYYY-MM-DD");

    const filters: Filters = [
      {
        name: `transactions.date`,
        value: dateFrom,
        comparison: "gte",
      },
      {
        name: `transactions.date`,
        value: dateTo,
        comparison: "lte",
      },
      {
        name: `transactions.kind`,
        value: group?.kind ?? "",
        comparison: "eq",
      },
    ];

    if (
      transactionGroupsMap &&
      group &&
      group?.refType === "transactionGroup"
    ) {
      const transactionGroup = transactionGroupsMap[group.refID];
      const vendorIDs: number[] = [];
      transactionGroup.vendorIDs.split(",").forEach((s) => {
        if (s) vendorIDs.push(parseInt(s));
      });

      const typeIDs: number[] = [];
      transactionGroup.typeIDs.split(",").forEach((s) => {
        if (s) typeIDs.push(parseInt(s));
      });

      const accountIDs: number[] = [];
      transactionGroup.accountIDs.split(",").forEach((s) => {
        if (s) accountIDs.push(parseInt(s));
      });

      if (vendorIDs.length) {
        filters.push({
          name: `transactions.vendorID`,
          value: vendorIDs,
          comparison: "in",
        });
      }

      if (typeIDs.length) {
        filters.push({
          name: `transactions.expenseTypeID`,
          value: typeIDs,
          comparison: "in",
        });
      }

      if (accountIDs.length) {
        filters.push({
          name: `transactions.accountIDs`,
          value: accountIDs,
          comparison: "in",
        });
      }
    } else {
      filters.push({
        name: `transactions.${group?.refType}ID`,
        value: group?.refID ?? "",
        comparison: "eq",
      });
    }

    setSearchParams({ filters: JSON.stringify(filters) });
    dispatch(setFormOpen(true, "transactions"));
  };
  return (
    <Box
      sx={{ p: 1, display: "flex", flex: 1, flexDirection: "column", gap: 2 }}
    >
      <Breadcrumbs sx={{ p: 0 }} size="sm">
        <Link
          color="neutral"
          component={RouterLink}
          to={`/${RouterConfig.reports}`}
        >
          Reports
        </Link>
        <Link color="neutral" disabled>
          {report?.name}
        </Link>
      </Breadcrumbs>
      <Typography level="h6">{report?.name}</Typography>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Select
          size="sm"
          placeholder="Frequency"
          value={_report?.frequency ?? ""}
          onChange={(_e, v) =>
            setReport((_r) => ({ ..._r, frequency: v ?? "" }))
          }
        >
          <Option value="daily">Daily</Option>
          <Option value="weekly">Weekly</Option>
          <Option value="monthly">Monthly</Option>
          <Option value="yearly">Yearly</Option>
        </Select>
        <Input
          type="date"
          value={_report?.dateFrom}
          onChange={(e) =>
            setReport((_r) => ({ ..._r, dateFrom: e.target.value ?? "" }))
          }
          size="sm"
        />
        <Button
          size="sm"
          onClick={() => save()}
          startDecorator={
            isLoading ? (
              <i className="fa-solid fa-sync fa-spin"></i>
            ) : (
              <i className="fa-solid fa-play"></i>
            )
          }
        >
          Run Report
        </Button>
      </Box>
      <Card sx={{ display: "flex" }}>
        <DataGridPro
          density="compact"
          autoHeight
          hideFooter
          processRowUpdate={onReportGroupUpdate}
          rows={report?.groups ?? []}
          components={{
            Toolbar: ReportGroupToolbar,
          }}
          columns={[
            {
              field: "id",
              headerName: "ID",
              editable: false,
              type: "number",
              width: 40,
            },
            {
              field: "name",
              headerName: "Name",
              editable: true,
              type: "string",
              width: 200,
            },
            {
              field: "refID",
              headerName: "RefID",
              editable: true,
              type: "number",
              width: 100,
              renderCell: (i) => (
                <TransactionGroupLabel
                  refType={i.row.refType}
                  id={i.row.refID}
                />
              ),
            },
            {
              field: "refType",
              headerName: "RefType",
              editable: true,
              type: "string",
              width: 120,
            },
            {
              field: "kind",
              headerName: "Kind",
              editable: true,
              type: "string",
              width: 80,
            },
            {
              field: "series",
              headerName: "Series",
              editable: true,
              type: "string",
              width: 200,
            },
            {
              field: "total",
              headerName: "Total",
              editable: true,
              type: "string",
              width: 100,
              align: "right",
              valueGetter: getterDivider("total", 100),
              valueSetter: setterDivider("total", 100),
              valueFormatter: (p) => currencyFormatter.format(p.value),
            },
            {
              field: "actions",
              type: "actions",
              headerName: "Actions",
              width: 100,
              cellClassName: "actions",
              getActions: (p) => [
                <GridActionsCellItem
                  key="delete"
                  icon={<DeleteIcon />}
                  label={`Delete`}
                  onClick={() => {
                    confirm({
                      description: `You are about to delete Group`,
                    })
                      .then(() => {
                        deleteReportGroup(p.row.id);
                      })
                      .catch(() => {
                        /* ... */
                      });
                  }}
                  color="inherit"
                />,
              ],
            },
          ]}
          experimentalFeatures={{ newEditingApi: true }}
          initialState={{
            pinnedColumns: { left: ["id"], right: ["actions"] },
          }}
        />
      </Card>
      <Card sx={{ display: "flex" }}>
        <DataGridPro
          autoHeight
          onCellClick={onCellClick}
          density="compact"
          rows={rows}
          columns={columns}
          components={{
            Footer: ReportFooter,
          }}
          initialState={{ pinnedColumns: { right: ["total"] } }}
        />
      </Card>
      <Card sx={{ height: 500 }}>
        <Tabs size="sm" sx={{ width: 100 }}>
          <TabList variant="soft" color="neutral">
            <Tab onClick={() => setCharType("bar")}>Bar</Tab>
            <Tab onClick={() => setCharType("line")}>Line</Tab>
          </TabList>
        </Tabs>
        <ReactApexChart
          type={chartType}
          key={chartType}
          height={400}
          series={data.series}
          options={data.options}
        />
      </Card>
    </Box>
  );
}

function ReportGroupToolbar() {
  const params = useParams();
  const reportID = params?.reportID ? parseInt(params?.reportID) : undefined;
  const [createReportGroup] = useCreateReportGroupMutation();

  const handleNewReportGroup = () => {
    createReportGroup({
      reportID,
    });
  };

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, alignItems: "start", gap: 1 }}
    >
      <Button variant="soft" onClick={handleNewReportGroup}>
        + New
      </Button>
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}

function ReportFooter() {
  const params = useParams();
  const reportID = params?.reportID ? parseInt(params?.reportID) : undefined;
  const { report } = useReport(reportID);

  let total = 0;
  report?.rows?.forEach((r) => (total += r.total));

  return (
    <>
      <Divider />
      <Box sx={{ p: 1, display: "flex", justifyContent: "end" }}>
        <b>Grand Total: {currencyFormatter.format(total / 100)}</b>
      </Box>
    </>
  );
}

function useReportResultsForDataGrid() {
  const params = useParams();
  const reportID = params?.reportID ? parseInt(params?.reportID) : undefined;
  const { report } = useReport(reportID);
  const dateFormat = {
    daily: "dd",
    weekly: "[Week] w",
    monthly: "MMM YY",
    yearly: "YYYY",
  };
  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Name",
      valueGetter: (p) =>
        moment(p.row.date).format(dateFormat[report?.frequency ?? 0]),
    },
  ];
  const firstRow = report?.rows?.length ? report?.rows[0] : undefined;

  if (firstRow) {
    for (const key in firstRow.items) {
      if (Object.prototype.hasOwnProperty.call(firstRow.items, key)) {
        const headerName =
          `${report?.groups[key]?.name} (${report?.groups[key]?.id})` ?? "";
        columns.push({
          field: headerName,
          headerName,
          width: 100,
          align: "right",
          renderCell: (p) => {
            const balance = (p.row[p.field]?.balance ?? 0) / 100;
            const amount = (p.row[p.field]?.amount ?? 0) / 100;
            return (
              <Box>
                <Tooltip
                  title={`Balance: ${currencyFormatter.format(balance)}`}
                >
                  <Typography
                    level="body2"
                    color={balance >= 0 ? "success" : "danger"}
                  >
                    {currencyFormatter.format(amount)}
                  </Typography>
                </Tooltip>
              </Box>
            );
          },
          valueGetter: getterDivider(headerName, 100),
          valueSetter: setterDivider(headerName, 100),
          valueFormatter: (p) => currencyFormatter.format(p.value),
        });
      }
    }
  }
  columns.push({
    field: "total",
    headerName: "Total",
    width: 100,
    align: "right",
    valueGetter: getterDivider("total", 100),
    valueSetter: setterDivider("total", 100),
    valueFormatter: (p) => currencyFormatter.format(p.value),
  });

  const rows: any = [];
  report?.rows?.forEach((r) => {
    const row = { ...r };
    r.items.forEach((i, index) => {
      const headerName =
        `${report?.groups[index]?.name} (${report?.groups[index]?.id})` ?? "";
      row[headerName] = i;
    });

    rows.push(row);
  });

  return { rows, columns };
}

type ApexChart = {
  series: { name: string; data: number[] }[];
  options: ApexCharts.ApexOptions;
};

function useReportResultsForChart(chartType: "bar" | "line") {
  const params = useParams();
  const reportID = params?.reportID ? parseInt(params?.reportID) : undefined;
  const { report } = useReport(reportID);
  const hasSeries = report?.hasSeries();
  const series = report?.series();

  const ret: ApexChart = {
    series: [],
    options: {
      dataLabels: {
        formatter: (v) => currencyFormatter.format(v as number),
      },
      stroke:
        chartType === "line"
          ? {
              width: 3,
              curve: "smooth",
            }
          : {},
      chart: {
        id: "basic-bar",
        stacked: chartType === "bar" && !hasSeries,
      },
      xaxis: {
        categories: [],
      },
    },
  };

  report?.rows?.forEach((r) => {
    ret.options.xaxis!.categories.push(r.name);
  });

  const firstRow = report?.rows?.length ? report?.rows[0] : undefined;

  if (hasSeries) {
    const groups = report?.groups;
    series?.forEach((s) => {
      const data: number[] = [];
      report?.rows?.forEach((r) => {
        let amount = 0;
        r.items.forEach((i, k) => {
          const group = groups?.find((g) => g.id === i.groupID);
          if (group?.series === s) {
            amount += i.amount;
          }
        });
        data.push(amount / 100);
      });
      ret.series.push({
        name: s,
        data,
      });
    });
  } else if (firstRow) {
    firstRow.items.forEach((i, index) => {
      const headerName = report?.groups[index]?.name ?? "";

      ret.series.push({
        name: headerName,
        data: [],
      });
    });

    report?.rows?.forEach((r) => {
      r.items.forEach((i, k) => {
        ret.series[k].data.push(i.amount / 100);
      });
    });
  }

  console.log(ret);

  return ret;
}

type Filter = {
  name: string;
  value: string | number | boolean | number[];
  comparison: ComparisonOperator;
};

type Filters = Filter[];

type ComparisonOperator =
  | "eq"
  | "gt"
  | "lt"
  | "neq"
  | "gte"
  | "lte"
  | "in"
  | "like"
  | null;
