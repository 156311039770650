import Score_Entity from "./score";
export default class Score extends Score_Entity {
  static fromList(scoresJSON: unknown): Array<Score> {
    const scores: Score[] = [];
    if (scoresJSON)
      Array.isArray(scoresJSON) &&
        scoresJSON.forEach((scoreJSON) => {
          scores.push(new Score(scoreJSON));
        });
    return scores;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
