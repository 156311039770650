import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button, Option, Select, Typography } from "@mui/joy";
import { Divider, Paper } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { NerdDateRangePicker } from "@nerdjs/nerd-ui";
import { DateTime } from "luxon";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import QuickbooksLogo from "../../assets/quickbooks-logo.png";
import { Transaction_Entity } from "../../entities/transaction";
import {
  currencyFormatter,
  getterDivider,
  setterDivider,
} from "../../helpers/datagrid";
import { useAccount } from "../../redux/account/accountHooks";
import { useExpenseType } from "../../redux/expenseType/expenseTypeHooks";
import {
  useDeleteTransactionMutation,
  useTransactionSyncMutation,
  useUpdateTransactionMutation,
} from "../../redux/transaction/transactionEndpoints";
import { useTransactions } from "../../redux/transaction/transactionHooks";
import { useVendor } from "../../redux/vendor/vendorHooks";
import { TransactionsSearch } from "../transaction/transactionSearch";

type ImportConfigOption = {
  dateFrom: DateTime | null;
  dateTo: DateTime | null;
};

/**
 * Transaction
 *
 * @returns {null} Transaction
 */
export default function TransactionsDataGrid({
  hideQuickbooksImport = false,
}: {
  hideQuickbooksImport?: boolean;
}) {
  const [params] = useSearchParams();
  const { transactions, isLoading } = useTransactions({
    filters: params.get("filters"),
  });
  const [updateTransaction] = useUpdateTransactionMutation();
  const [deleteTransaction] = useDeleteTransactionMutation();
  const [syncTransaction, { isLoading: syncLoading }] =
    useTransactionSyncMutation();

  const [importConfig, setImportConfig] = useState<ImportConfigOption>({
    dateFrom: DateTime.now().minus({ days: 7 }),
    dateTo: DateTime.now(),
  });

  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  const onTransactionUpdate = async (
    _newTransaction: Transaction_Entity,
    _oldTransaction: Transaction_Entity
  ) => {
    return new Promise<Transaction_Entity>((resolve) => {
      updateTransaction({
        id: _newTransaction.id,
        body: _newTransaction,
      });
      setTimeout(() => {
        resolve({ ..._newTransaction });
      }, 50);
    });
  };

  const handleImport = () => {
    syncTransaction({
      dateFrom: importConfig.dateFrom?.toFormat("yyyy-MM-dd"),
      dateTo: importConfig.dateTo?.toFormat("yyyy-MM-dd"),
    });
  };

  return (
    <Paper
      sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 1, p: 1 }}
    >
      {!hideQuickbooksImport ? (
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <NerdDateRangePicker
            startTextFieldProps={{ size: "small" }}
            endTextFieldProps={{ size: "small" }}
            value={[importConfig.dateFrom, importConfig.dateTo]}
            onChange={(newValue) => {
              console.log(newValue);
              setImportConfig((c) => ({
                ...c,
                dateFrom: newValue[0],
                dateTo: newValue[1],
              }));
            }}
          />
          <Button
            sx={{ background: "#4E9934" }}
            onClick={handleImport}
            startDecorator={
              <img
                src={QuickbooksLogo}
                style={{ height: 20, filter: "invert(1)" }}
              />
            }
            color="success"
            size="sm"
            endDecorator={
              syncLoading ? (
                <i className="fa-solid fa-sync fa-spin"></i>
              ) : (
                <i className="fa-sharp fa-solid fa-arrow-down"></i>
              )
            }
          >
            Quickbooks Import
          </Button>
        </Box>
      ) : (
        []
      )}
      <DataGridPro
        density="compact"
        apiRef={apiRef}
        loading={isLoading}
        processRowUpdate={onTransactionUpdate}
        rows={transactions ?? []}
        columns={[
          {
            field: "id",
            headerName: "ID",
            editable: false,
            type: "number",
            width: 40,
          },
          {
            field: "kind",
            headerName: "Kind",
            editable: true,
            type: "string",
            width: 60,
          },
          {
            field: "date",
            headerName: "Date",
            editable: true,
            type: "string",
            width: 100,
            valueFormatter: (p) => moment(p.value).format("MM/DD/YYYY"),
          },
          {
            field: "amount",
            headerName: "Amount",
            editable: true,
            type: "string",
            width: 100,
            align: "right",
            valueGetter: getterDivider("amount", 100),
            valueSetter: setterDivider("amount", 100),
            valueFormatter: (p) => currencyFormatter.format(p.value),
          },
          {
            field: "descrition",
            headerName: "Description",
            editable: true,
            type: "string",
            width: 200,
          },
          {
            field: "vendorID",
            headerName: "Vendor",
            editable: true,
            type: "string",
            width: 200,
            renderCell: (i) => <VendorLabel id={i.row.vendorID} />,
          },
          {
            field: "accountID",
            headerName: "Account",
            editable: true,
            type: "string",
            width: 200,
            renderCell: (i) => <AccountLabel id={i.row.accountID} />,
          },
          {
            field: "expenseTypeID",
            headerName: "ExpenseType",
            editable: true,
            type: "string",
            width: 200,
            renderCell: (i) => <ExpenseTypeLabel id={i.row.expenseTypeID} />,
          },
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (p) => [
              <GridActionsCellItem
                key="delete"
                icon={<DeleteIcon />}
                label={`Delete`}
                onClick={() => {
                  confirm({
                    description: `You are about to delete a transaction`,
                  })
                    .then(() => {
                      deleteTransaction(p.row.id);
                    })
                    .catch(() => {
                      /* ... */
                    });
                }}
                color="inherit"
              />,
            ],
          },
        ]}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: TransactionToolbar,
          Footer: TransactionFooter,
        }}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "id",
                sort: "desc",
              },
            ],
          },
          pinnedColumns: { left: ["id"], right: ["amount", "actions"] },
        }}
      />
    </Paper>
  );
}

function TransactionToolbar() {
  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        p: 1,
        alignItems: "start",
        gap: 1,
        position: "relative",
      }}
    >
      <TransactionsSearch />
    </Box>
  );
}

function TransactionFooter() {
  const [params] = useSearchParams();
  const { transactions, isLoading } = useTransactions({
    filters: params.get("filters"),
  });

  let debits = 0;
  let credits = 0;

  transactions?.forEach((t) => {
    if (t.kind === "debit") debits += t.amount;
    if (t.kind === "credit") credits += t.amount;
  });
  return (
    <>
      <Divider />
      <Box
        sx={{
          p: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Box>
          {debits ? (
            <Typography level="body2">
              Debits: {currencyFormatter.format(debits / 100)}
            </Typography>
          ) : (
            []
          )}
          {credits ? (
            <Typography level="body2">
              Credits: {currencyFormatter.format(credits / 100)}
            </Typography>
          ) : (
            []
          )}
          {credits && debits ? (
            <Typography level="body2">
              Total: {currencyFormatter.format((credits - debits) / 100)}
            </Typography>
          ) : (
            []
          )}
        </Box>
        <Box>
          <Typography level="body2">
            {transactions?.length} Row
            {(transactions?.length ?? 0) > 1 ? "s" : ""}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

function VendorLabel({ id }: { id: number }) {
  const { vendor } = useVendor(id);

  return <Typography level="body2">{vendor?.name}</Typography>;
}

function AccountLabel({ id }: { id: number }) {
  const { account } = useAccount(id);

  return <Typography level="body2">{account?.name}</Typography>;
}

function ExpenseTypeLabel({ id }: { id: number }) {
  const { expenseType } = useExpenseType(id);

  return <Typography level="body2">{expenseType?.name}</Typography>;
}
