import { createAction, nanoid } from "@reduxjs/toolkit";

export const setTabIndex = createAction(
  "setTabIndex",
  function prepare(tabID: string, tabIndex: number) {
    return {
      payload: {
        tabID,
        tabIndex,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setFormOpen = createAction(
  "setFormOpen",
  function prepare(open: boolean, formID: string) {
    return {
      payload: {
        open,
        formID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
