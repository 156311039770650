import { Autocomplete, Icon, InputBase, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ExpenseType } from "../../entities/expenseType";
import {
  useExpenseType,
  useExpenseTypes,
} from "../../redux/expenseType/expenseTypeHooks";

/**
 * @param {expenseTypeAutocompleteProps} expenseTypeAutocompleteProps PriorityLevel object from API
 * @returns {ReactElement} ExpenseType Autocomplete
 */
export function ExpenseTypeAutocomplete({
  expenseTypeID,
  onChange,
  variant,
  size,
  helperText,
  disabled,
  autoFocus,
}: expenseTypeAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const { expenseTypes } = useExpenseTypes();
  const { expenseType } = useExpenseType(expenseTypeID);
  const dispatch = useDispatch();

  useEffect(() => {
    if (expenseType) {
      setInputValue(expenseType.name);
    }
  }, [expenseType]);

  return (
    <Autocomplete
      value={expenseType ?? null}
      onChange={(_e, newValue) => {
        onChange(newValue);
      }}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={(_e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      size={size}
      getOptionLabel={(option) => `${option.name}`}
      options={expenseTypes ?? []}
      clearIcon={<Icon sx={{ fontSize: 15 }} className="fa-solid fa-xmark" />}
      renderInput={(params) =>
        variant === "inputBase" ? (
          <div ref={params.InputProps.ref}>
            <InputBase
              inputProps={{ ...params.inputProps }}
              fullWidth
              sx={{ fontSize: 14 }}
              placeholder="Select a expenseType..."
              autoFocus={autoFocus}
            />
          </div>
        ) : (
          <TextField
            variant={variant}
            {...params}
            fullWidth
            sx={{ fontSize: 14 }}
            placeholder="Select a expenseType..."
            autoFocus={autoFocus}
            label="ExpenseType"
            helperText={helperText}
          />
        )
      }
    />
  );
}

type expenseTypeAutocompleteProps = {
  expenseTypeID?: number;
  onChange: (expenseType: ExpenseType | null) => void;
  variant?: "standard" | "filled" | "outlined" | "inputBase" | undefined;
  size?: "small" | "medium" | undefined;
  helperText?: string;
  disabled?: boolean;
  autoFocus?: boolean;
};
