import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { Quota_Entity } from "../../entities/quota";
import { budgetApi } from "../api/budgetApi";

const quotasAdapter = createEntityAdapter<Quota_Entity>();
const initialState = quotasAdapter.getInitialState();

export const quotaEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getQuotas: build.query<EntityState<Quota_Entity>, void>({
      query: () => `quotas`,
      transformResponse: (responseData: Quota_Entity[]) => {
        return quotasAdapter.setAll(initialState, responseData);
      },
      providesTags: ["quotas"],
    }),
    getQuota: build.query<Quota_Entity, number>({
      query: (id) => `quotas/${id}`,
      providesTags: (result, error, id) => [{ type: "quotas", id }],
    }),
    createQuota: build.mutation<Quota_Entity, Partial<Quota_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `quotas`,
      }),
      invalidatesTags: ["quotas"],
    }),
    updateQuota: build.mutation<
      void,
      { id: number; body: Partial<Quota_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `quotas/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "quotas", id },
        "quotas",
      ],
    }),
    deleteQuota: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `quotas/${id}`,
      }),
      invalidatesTags: ["quotas"],
    }),
  }),
});

export const {
  useGetQuotaQuery,
  useGetQuotasQuery,
  useCreateQuotaMutation,
  useDeleteQuotaMutation,
  useUpdateQuotaMutation,
} = quotaEndpoints;

export default quotaEndpoints;

export const selectQuotasResult = quotaEndpoints.endpoints.getQuotas.select();

const selectQuotasData = createSelector(
  selectQuotasResult,
  (quotasResult) => quotasResult.data
);

const store = configureStore({
  reducer: {
    [budgetApi.reducerPath]: budgetApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllQuotas, selectById: selectQuotaById } =
  quotasAdapter.getSelectors<RootState>(
    (state) => selectQuotasData(state) ?? initialState
  );
