/**
 * TransactionGroup_Entity type guard.
 *
 * @param {any} transactionGroupJson TransactionGroup object from API
 * @returns {boolean} Return true if type is TransactionGroup_Entity
 */
function validator(
  transactionGroupJson: unknown
): transactionGroupJson is TransactionGroup_Entity {
  if (
    typeof transactionGroupJson === "object" &&
    transactionGroupJson != null
  ) {
    TransactionGroup_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(transactionGroupJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class TransactionGroup_Entity {
  static requiredFields = [
    "id",
    "name",
    "description",
    "typeIDs",
    "vendorIDs",
    "accountIDs",
    "createdAt",
    "updatedAt",
  ];

  constructor(transactionGroupJson: unknown) {
    if (validator(transactionGroupJson)) {
      this.id = transactionGroupJson.id;
      this.name = transactionGroupJson.name;
      this.description = transactionGroupJson.description;
      this.typeIDs = transactionGroupJson.typeIDs;
      this.vendorIDs = transactionGroupJson.vendorIDs;
      this.accountIDs = transactionGroupJson.accountIDs;
      this.createdAt = transactionGroupJson.createdAt;
      this.updatedAt = transactionGroupJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ TransactionGroup_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  description: string;
  typeIDs: string;
  vendorIDs: string;
  accountIDs: string;
  createdAt: string;
  updatedAt: string;
}
