import ExpenseType_Entity from "./expenseType";
export default class ExpenseType extends ExpenseType_Entity {
  static fromList(expenseTypesJSON: unknown): Array<ExpenseType> {
    const expenseTypes: ExpenseType[] = [];
    if (expenseTypesJSON)
      Array.isArray(expenseTypesJSON) &&
        expenseTypesJSON.forEach((expenseTypeJSON) => {
          expenseTypes.push(new ExpenseType(expenseTypeJSON));
        });
    return expenseTypes;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
