import {
  Button,
  IconButton,
  Input,
  Option,
  Select,
  Sheet,
  Link,
  Typography,
  Tooltip,
} from "@mui/joy";
import { Box, Divider } from "@mui/material";
import { NerdDateRangePicker } from "@nerdjs/nerd-ui";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsSankey from "highcharts/modules/sankey";
import { DateTime } from "luxon";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Flow_Entity } from "../entities/flow";
import { Node_Entity } from "../entities/node";
import { Sankey_Entity } from "../entities/sankey";
import ColorPicker from "../hooks/colorPicker/colorPicker";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  useCreateFlowMutation,
  useDeleteFlowMutation,
  useUpdateFlowMutation,
} from "../redux/flow/flowEndpoints";
import {
  useCreateNodeMutation,
  useDeleteNodeMutation,
  useUpdateNodeMutation,
} from "../redux/node/nodeEndpoints";
import { useUpdateSankeyMutation } from "../redux/sankey/sankeyEndpoints";
import { useSankey } from "../redux/sankey/sankeyHooks";
import { useTransactionGroups } from "../redux/transactionGroup/transactionGroupHooks";
import Exporting from "highcharts/modules/exporting";
import { currencyFormatter } from "../helpers/datagrid";

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: "none",
  ...draggableStyle,
});

/**
 *
 * @returns {ReactElement} SankeyRoute page
 */
export function SankeyRoute() {
  const params = useParams();
  const sankeyID = params.sankeyID ? parseInt(params.sankeyID) : undefined;
  const [createFlow] = useCreateFlowMutation();
  const [updateFlow] = useUpdateFlowMutation();
  const [createNode] = useCreateNodeMutation();
  const [updateSankey] = useUpdateSankeyMutation();
  const { sankey } = useSankey(sankeyID);
  const [_sankey, setSankey] = useState<Partial<Sankey_Entity>>();

  useEffect(() => {
    if (sankey) {
      setSankey(sankey);
    }
  }, [sankey]);

  const save = () => {
    if (_sankey?.id) updateSankey({ id: _sankey.id, body: _sankey });
  };

  function onDragEnd(result: any) {
    const newItems = [...(_sankey?.flows ?? [])];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setSankey((s) => ({ ...s, flows: newItems }));

    newItems.forEach((i, index) => {
      updateFlow({ id: i.id, body: { ...i, position: index + 1 } });
    });
  }

  highchartsSankey(Highcharts);
  Exporting(Highcharts);
  const options = {
    title: {
      text: _sankey?.name,
    },
    exporting: {
      enabled: true, // Enables the export button
      buttons: {
        contextButton: {
          menuItems: ["downloadPNG", "downloadSVG"],
        },
      },
    },
    plotOptions: {
      sankey: {
        linkColorMode: "to",
      },
    },
    subtitle: {
      text: _sankey?.description,
    },
    accessibility: {
      point: {
        valueDescriptionFormat:
          "{index}. {point.from} to {point.to}, {point.weight}.",
      },
    },
    tooltip: {
      headerFormat: "",
      pointFormat:
        "-> {point.fromNode.name} \u2192 {point.toNode.name}: {point.weight:.2f}",
      nodeFormat: "$ {point.name}: {point.sum:.2f}",
    },
    series: [
      {
        keys: ["from", "to", "weight"],
        nodes: _sankey?.nodes?.reduce<any>((a, v) => {
          a.push({ id: v.name, color: v.color });
          return a;
        }, []),
        data: _sankey?.flows?.reduce<any>((a, v) => {
          const from = _sankey.nodes?.find((e) => e.id === v.nodeFrom);
          const to = _sankey.nodes?.find((e) => e.id === v.nodeTo);
          a.push([from?.name, to?.name, v.weight]);
          return a;
        }, []),
        type: "sankey",
        name: "Sankey demo series",
      },
    ],
  };
  return (
    <Box sx={{ p: 1, display: "flex", flex: 1 }}>
      <HighchartsReact
        containerProps={{ style: { flexGrow: 1 } }}
        highcharts={Highcharts}
        options={options}
      />
      <Divider orientation="vertical" flexItem />
      <Sheet
        sx={{
          overflow: "auto",
          maxHeight: "calc(100vh - 80px)",
          width: 320,
          p: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography
          level="h6"
          startDecorator={<i className="fa-solid fa-gear"></i>}
        >
          Configuration
        </Typography>
        <Input
          variant="soft"
          value={_sankey?.name ?? ""}
          onChange={(e) => setSankey((s) => ({ ...s, name: e.target.value }))}
          onBlur={save}
          startDecorator="Name:"
        />
        <Input
          variant="soft"
          value={_sankey?.description ?? ""}
          onChange={(e) =>
            setSankey((s) => ({ ...s, description: e.target.value }))
          }
          onBlur={save}
          startDecorator="Description:"
        />
        <NerdDateRangePicker
          startTextFieldProps={{ size: "small" }}
          endTextFieldProps={{ size: "small" }}
          value={[
            _sankey?.dateFrom !== "0001-01-01T00:00:00Z"
              ? DateTime.fromISO(_sankey?.dateFrom ?? "")
              : DateTime.now().minus({ days: 7 }),
            _sankey?.dateTo !== "0001-01-01T00:00:00Z"
              ? DateTime.fromISO(_sankey?.dateTo ?? "")
              : DateTime.now(),
          ]}
          onChange={(newValue) => {
            setSankey((s) => ({
              ...s,
              dateFrom: newValue[0]?.toISO(),
              dateTo: newValue[1]?.toISO(),
            }));

            updateSankey({
              id: _sankey!.id!,
              body: {
                ..._sankey,
                dateFrom: newValue[0]?.toISO(),
                dateTo: newValue[1]?.toISO(),
              },
            });
          }}
        />
        <Box>
          <Typography
            endDecorator={
              <IconButton
                onClick={() => {
                  createNode({ sankeyID: sankey?.id });
                }}
                size="sm"
                color="neutral"
                variant="plain"
              >
                <i className="fa-solid fa-plus"></i>
              </IconButton>
            }
          >
            Nodes
          </Typography>
          <Divider />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 1 }}>
            {sankey?.nodes?.map((n) => (
              <Node node={n} key={n.id} />
            ))}
          </Box>
        </Box>
        <Box>
          <Typography
            endDecorator={
              <IconButton
                onClick={() => {
                  createFlow({ sankeyID: sankey?.id });
                }}
                size="sm"
                color="neutral"
                variant="plain"
              >
                <i className="fa-solid fa-plus"></i>
              </IconButton>
            }
          >
            Flows
          </Typography>
          <Divider />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={"Box"}>
              {(provided: any) => {
                return (
                  <Box
                    ref={provided.innerRef}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      mt: 1,
                    }}
                  >
                    {_sankey?.flows?.map((f, i) => {
                      return (
                        <Draggable key={f.id} draggableId={`${f.id}`} index={i}>
                          {(provided: any, snapshot: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Flow
                                flow={f}
                                key={f.id}
                                nodes={_sankey?.nodes ?? []}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Box>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Box>
      </Sheet>
    </Box>
  );
}

function Node({ node }: { node: Node_Entity }) {
  const [_node, setNode] = useState(node);
  const [updateNode] = useUpdateNodeMutation();
  const [deleteNode] = useDeleteNodeMutation();
  const { transactionGroups } = useTransactionGroups();
  const [expanded, setExpanded] = useState(!node.name);

  const save = () => {
    if (_node?.id) updateNode({ id: _node.id, body: _node });
  };

  useEffect(() => {
    setNode(node);
  }, [node]);

  const form = (
    <>
      <Input
        autoFocus={!_node.name}
        size="sm"
        startDecorator="Name:"
        value={_node.name}
        onChange={(e) => setNode((s) => ({ ...s, name: e.target.value }))}
        onBlur={save}
      />
      <Box sx={{ display: "flex" }}>
        <Select
          sx={{ flexGrow: 1 }}
          size="sm"
          startDecorator="Transaction Group:"
          value={_node.transactionGroup}
          onChange={(e, v) => {
            setNode((s) => ({ ...s, transactionGroup: v ?? -1 }));
            updateNode({
              id: _node.id,
              body: { ..._node, transactionGroup: v ?? -1 },
            });
          }}
        >
          {transactionGroups?.map((n) => (
            <Option key={n.id} value={n.id}>
              {n.name}
            </Option>
          ))}
        </Select>
        {_node.transactionGroup > 0 ? (
          <IconButton
            size="sm"
            onClick={() => {
              updateNode({
                id: _node.id,
                body: { ..._node, transactionGroup: -1 },
              });
            }}
            variant="plain"
            color="neutral"
          >
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
        ) : undefined}
      </Box>
      <Input
        disabled={_node.transactionGroup > 0}
        size="sm"
        startDecorator="Amount:"
        value={_node.amount}
        type="tel"
        onChange={(e) =>
          setNode((s) => ({ ...s, amount: parseInt(e.target.value) }))
        }
        onBlur={save}
      />
      <Box sx={{ display: "flex", gap: 1 }}>
        <ColorPicker
          onChange={(e) => {
            setNode((s) => ({ ...s, color: e }));
            updateNode({
              id: _node.id,
              body: { ..._node, color: e },
            });
          }}
          color={_node.color}
          label="Color:"
        />
        <Link
          color="danger"
          level="body2"
          onClick={() => deleteNode(node.id)}
          startDecorator={<i className="fa-solid fa-trash"></i>}
        >
          Delete
        </Link>
      </Box>
      <Button
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setExpanded(false);
        }}
      >
        Done
      </Button>
    </>
  );

  const summary = (
    <Typography
      startDecorator={
        <Box
          sx={{
            background: _node.color,
            width: 18,
            height: 18,
            borderRadius: 2,
          }}
        />
      }
      level="body2"
      sx={{ fontWeight: 800 }}
    >
      <Typography sx={{ mr: 1 }} level="body5">
        #{_node.id}
      </Typography>{" "}
      {_node.name}
    </Typography>
  );

  return (
    <Sheet
      variant="soft"
      onClick={() => setExpanded(true)}
      sx={{
        cursor: "pointer",
        borderRadius: 1,
        p: 0.5,
        display: "flex",
        flexDirection: expanded ? "column" : "row",
        gap: 1,
        justifyContent: "space-between",
        transition: "height: .5s",
        "&:hover": {
          background: "rgba(155, 155, 155,.2)",
        },
      }}
    >
      {expanded ? form : summary}
    </Sheet>
  );
}

function Flow({ flow, nodes }: { flow: Flow_Entity; nodes: Node_Entity[] }) {
  const [_flow, setFlow] = useState(flow);
  const [expanded, setExpanded] = useState(!flow.nodeFrom && !flow.nodeTo);
  const [updateFlow] = useUpdateFlowMutation();
  const [deleteFlow] = useDeleteFlowMutation();
  const save = () => {
    if (_flow?.id) updateFlow({ id: _flow.id, body: _flow });
  };

  useEffect(() => {
    setFlow(flow);
  }, [flow]);

  const from = nodes.find((e) => e.id === flow.nodeFrom);
  const to = nodes.find((e) => e.id === flow.nodeTo);

  const summary = (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexGrow: 1,
          textOverflow: "ellipsis",
        }}
      >
        <Box sx={{ width: 80 }}>
          <Tooltip title={`${from?.name} #${from?.id}`}>
            <Typography
              noWrap
              startDecorator={
                <Box
                  sx={{
                    background: from?.color,
                    width: 18,
                    height: 18,
                    borderRadius: 2,
                  }}
                />
              }
              level="body2"
              sx={{ fontWeight: 800, flex: 1 }}
            >
              <Typography sx={{ mr: 1 }} level="body5">
                #{_flow.id}
              </Typography>{" "}
              {from?.name}
            </Typography>
          </Tooltip>
        </Box>
        <i className="fa-solid fa-arrow-right"></i>
        <Box sx={{ width: 80 }}>
          <Tooltip title={`${to?.name} #${to?.id}`}>
            <Typography
              noWrap
              startDecorator={
                <Box
                  sx={{
                    background: to?.color,
                    width: 18,
                    height: 18,
                    borderRadius: 2,
                  }}
                />
              }
              level="body2"
              sx={{ fontWeight: 800, flex: 1 }}
            >
              {to?.name}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      <Typography level="body2" sx={{ fontWeight: 800 }}>
        {currencyFormatter.format(flow.weight / 1000)}
      </Typography>
    </>
  );

  const form = (
    <>
      <Select
        autoFocus={!_flow.nodeFrom}
        size="sm"
        startDecorator="From:"
        value={_flow.nodeFrom}
        onChange={(e, v) => {
          setFlow((s) => ({ ...s, from: v }));
          updateFlow({ id: _flow.id, body: { ..._flow, nodeFrom: v ?? -1 } });
        }}
      >
        {nodes.map((n) => (
          <Option key={n.id} value={n.id}>
            {n.name}
          </Option>
        ))}
      </Select>
      <Select
        size="sm"
        startDecorator="To:"
        value={_flow.nodeTo}
        onChange={(e, v) => {
          setFlow((s) => ({ ...s, to: v }));
          updateFlow({ id: _flow.id, body: { ..._flow, nodeTo: v ?? -1 } });
        }}
      >
        {nodes.map((n) => (
          <Option key={n.id} value={n.id}>
            {n.name}
          </Option>
        ))}
      </Select>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Input
          size="sm"
          startDecorator="Weight:"
          value={_flow.weight}
          type="tel"
          onChange={(e) =>
            setFlow((s) => ({ ...s, weight: parseInt(e.target.value) }))
          }
          onBlur={save}
        />
        <Input
          size="sm"
          startDecorator="Position:"
          value={_flow.position}
          type="tel"
          onChange={(e) =>
            setFlow((s) => ({ ...s, position: parseInt(e.target.value) }))
          }
          onBlur={save}
        />
        <Link
          color="danger"
          level="body2"
          onClick={() => deleteFlow(flow.id)}
          startDecorator={<i className="fa-solid fa-trash"></i>}
        >
          Delete
        </Link>
      </Box>
      <Button
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setExpanded(false);
        }}
      >
        Done
      </Button>
    </>
  );

  return (
    <Sheet
      variant="soft"
      onClick={() => setExpanded(true)}
      sx={{
        cursor: "pointer",
        borderRadius: 1,
        p: 0.5,
        display: "flex",
        flexDirection: expanded ? "column" : "row",
        gap: 1,
        justifyContent: "space-between",
        transition: "height: .5s",
        "&:hover": {
          background: "rgba(155, 155, 155,.2)",
        },
      }}
    >
      {expanded ? form : summary}
    </Sheet>
  );
}
