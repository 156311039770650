import { Quota } from "../quota";

/**
 * Score_Entity type guard.
 *
 * @param {any} scoreJson Score object from API
 * @returns {boolean} Return true if type is Score_Entity
 */
function validator(scoreJson: unknown): scoreJson is Score_Entity {
  if (typeof scoreJson === "object" && scoreJson != null) {
    Score_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(scoreJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Score_Entity {
  static requiredFields = [
    "id",
    "date",
    "grade",
    "quotaGrade",
    "plGrade",
    "quotas",
    "createdAt",
    "updatedAt",
  ];

  constructor(scoreJson: unknown) {
    if (validator(scoreJson)) {
      this.id = scoreJson.id;
      this.date = scoreJson.date;
      this.grade = scoreJson.grade;
      this.quotaGrade = scoreJson.quotaGrade;
      this.plGrade = scoreJson.plGrade;
      this.quotas = scoreJson.quotas;
      this.createdAt = scoreJson.createdAt;
      this.updatedAt = scoreJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Score_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  date: string;
  grade: number;
  quotaGrade: number;
  plGrade: number;
  quotas: Array<Quota>;
  createdAt: string;
  updatedAt: string;
}
