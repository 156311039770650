import { useMemo } from "react";
import { useGetSankeyQuery, useGetSankeysQuery } from "./sankeyEndpoints";
import { Sankey } from "../../entities/sankey";

export function useSankey(sankeyID: number | undefined) {
  const sankeyQuery = useGetSankeyQuery(sankeyID ?? 0, {
    skip: (sankeyID ?? 0) <= 0,
  });
  const sankeyEntity = sankeyQuery.data;

  return useMemo(() => {
    const ret: typeof sankeyQuery & { sankey?: Sankey | undefined } = {
      ...sankeyQuery,
    };
    if (sankeyEntity) ret.sankey = new Sankey(sankeyEntity);
    return ret;
  }, [sankeyEntity]);
}

export function useSankeys() {
  const sankeyQuery = useGetSankeysQuery();
  const sankeyEntitiesMap = sankeyQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof sankeyQuery & {
      sankeys?: Sankey[];
      sankeysMap?: { [id: number]: Sankey };
    } = {
      ...sankeyQuery,
      sankeys: [],
      sankeysMap: {},
    };
    if (sankeyEntitiesMap) {
      const sankeys = [];
      const sankeysMap = {};

      for (const key in sankeyEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(sankeyEntitiesMap, key)) {
          const c = sankeyEntitiesMap[key];
          let sankey;
          if (c) {
            sankey = new Sankey(c);
            sankeys.push(sankey);
            sankeysMap[sankey.id] = sankey;
          }
        }
      }
      ret.sankeys = sankeys;
      ret.sankeysMap = sankeysMap;
    }

    return ret;
  }, [sankeyEntitiesMap]);
}
