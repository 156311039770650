import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button, Typography } from "@mui/joy";
import { Paper } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useConfirm } from "material-ui-confirm";
import { Sankey_Entity } from "../../entities/sankey";
import {
  currencyFormatter,
  getterDivider,
  setterDivider,
} from "../../helpers/datagrid";
import { useAccount } from "../../redux/account/accountHooks";
import { useExpenseType } from "../../redux/expenseType/expenseTypeHooks";
import {
  useCreateSankeyMutation,
  useDeleteSankeyMutation,
  useUpdateSankeyMutation,
} from "../../redux/sankey/sankeyEndpoints";
import { useSankeys } from "../../redux/sankey/sankeyHooks";
import { useTransactionGroup } from "../../redux/transactionGroup/transactionGroupHooks";
import { useVendor } from "../../redux/vendor/vendorHooks";
import { useNavigate } from "react-router-dom";

/**
 * Sankey
 *
 * @returns {null} Sankey
 */
export default function SankeysDataGrid() {
  const { sankeys, isLoading } = useSankeys();
  const [updateSankey] = useUpdateSankeyMutation();
  const navigate = useNavigate();

  const apiRef = useGridApiRef();
  const onSankeyUpdate = async (
    _newSankey: Sankey_Entity,
    _oldSankey: Sankey_Entity
  ) => {
    return new Promise<Sankey_Entity>((resolve) => {
      updateSankey({
        id: _newSankey.id,
        body: _newSankey,
      });
      setTimeout(() => {
        resolve({ ..._newSankey });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1, display: "flex" }}>
      <DataGridPro
        density="compact"
        apiRef={apiRef}
        loading={isLoading}
        processRowUpdate={onSankeyUpdate}
        rows={sankeys ?? []}
        columns={[
          {
            field: "id",
            headerName: "ID",
            editable: false,
            type: "number",
            width: 40,
          },
          {
            field: "name",
            headerName: "Name",
            editable: true,
            type: "string",
            width: 200,
          },
          {
            field: "description",
            headerName: "Description",
            editable: true,
            type: "string",
            flex: 1,
          },
          {
            field: "view",
            headerName: "",
            editable: false,
            type: "string",
            width: 160,
            align: "right",
            renderCell: (p) => (
              <Button
                size="sm"
                onClick={() => {
                  navigate(`${p.row.id}`);
                }}
                endDecorator={<i className="fa-solid fa-chevron-right"></i>}
              >
                View Sankey
              </Button>
            ),
          },
        ]}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: SankeyToolbar,
        }}
        initialState={{
          pinnedColumns: { left: ["id"], right: ["actions"] },
        }}
      />
    </Paper>
  );
}

function SankeyToolbar() {
  const [createSankey] = useCreateSankeyMutation();

  const handleNewSankey = () => {
    const newSankey = {
      id: -1,
      name: "",
      description: "",
      createdAt: "",
      updatedAt: "",
    };
    createSankey(newSankey);
  };

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, alignItems: "start", gap: 1 }}
    >
      <Button variant="soft" onClick={handleNewSankey}>
        + New
      </Button>
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}

export function TransactionGroupLabel({
  id,
  refType,
}: {
  id: number;
  refType: string;
}) {
  const { transactionGroup } = useTransactionGroup(id, {
    skip: refType !== "transactionGroup",
  });
  const { expenseType } = useExpenseType(id, {
    skip: refType !== "expenseType",
  });
  const { account } = useAccount(id, {
    skip: refType !== "account",
  });
  const { vendor } = useVendor(id, {
    skip: refType !== "vendor",
  });

  let label = "";
  if (refType === "expenseType") label = expenseType?.name ?? "";
  if (refType === "transactionGroup") label = transactionGroup?.name ?? "";
  if (refType === "account") label = account?.name ?? "";
  if (refType === "vendor") label = vendor?.name ?? "";

  return <Typography level="body2">{label}</Typography>;
}
