import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { ReportGroup_Entity } from "../../entities/reportGroup";
import { budgetApi } from "../api/budgetApi";

const reportGroupsAdapter = createEntityAdapter<ReportGroup_Entity>();
const initialState = reportGroupsAdapter.getInitialState();

export const reportGroupEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getReportGroups: build.query<EntityState<ReportGroup_Entity>, void>({
      query: () => `reportGroups`,
      transformResponse: (responseData: ReportGroup_Entity[]) => {
        return reportGroupsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["reportGroups"],
    }),
    getReportGroup: build.query<ReportGroup_Entity, number>({
      query: (id) => `reportGroups/${id}`,
      providesTags: (_result, _error, id) => [{ type: "reportGroups", id }],
    }),
    createReportGroup: build.mutation<
      ReportGroup_Entity,
      Partial<ReportGroup_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `reportGroups`,
      }),
      invalidatesTags: ["reportGroups", "reports"],
    }),
    updateReportGroup: build.mutation<
      void,
      { id: number; body: Partial<ReportGroup_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `reportGroups/${args.id}`,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "reportGroups", id },
        "reportGroups",
      ],
    }),
    deleteReportGroup: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `reportGroups/${id}`,
      }),
      invalidatesTags: ["reportGroups", "reports"],
    }),
  }),
});

export const {
  useGetReportGroupQuery,
  useGetReportGroupsQuery,
  useCreateReportGroupMutation,
  useDeleteReportGroupMutation,
  useUpdateReportGroupMutation,
} = reportGroupEndpoints;

export default reportGroupEndpoints;

export const selectReportGroupsResult =
  reportGroupEndpoints.endpoints.getReportGroups.select();

const selectReportGroupsData = createSelector(
  selectReportGroupsResult,
  (reportGroupsResult) => reportGroupsResult.data
);

const store = configureStore({
  reducer: {
    [budgetApi.reducerPath]: budgetApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const {
  selectAll: selectAllReportGroups,
  selectById: selectReportGroupById,
} = reportGroupsAdapter.getSelectors<RootState>(
  (state) => selectReportGroupsData(state) ?? initialState
);
