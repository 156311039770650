import { useMemo } from "react";
import { Score } from "../../entities/score";
import { useGetScoreQuery, useGetScoresQuery } from "./scoreEndpoints";

export function useScore(scoreID: number | undefined) {
  const scoreQuery = useGetScoreQuery(scoreID ?? 0, {
    skip: (scoreID ?? 0) <= 0,
  });
  const scoreEntity = scoreQuery.data;

  return useMemo(() => {
    const ret: typeof scoreQuery & { score?: Score | undefined } = {
      ...scoreQuery,
    };
    if (scoreEntity) ret.score = new Score(scoreEntity);
    return ret;
  }, [scoreEntity]);
}

export function useScores() {
  const scoreQuery = useGetScoresQuery();
  const scoreEntitiesMap = scoreQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof scoreQuery & {
      scores?: Score[];
      scoresMap?: { [id: number]: Score };
    } = {
      ...scoreQuery,
      scores: [],
      scoresMap: {},
    };
    if (scoreEntitiesMap) {
      const scores = [];
      const scoresMap = {};

      for (const key in scoreEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(scoreEntitiesMap, key)) {
          const c = scoreEntitiesMap[key];
          const score = new Score(c);
          scores.push(score);
          scoresMap[score.id] = score;
        }
      }
      ret.scores = scores;
      ret.scoresMap = scoresMap;
    }

    return ret;
  }, [scoreEntitiesMap]);
}
