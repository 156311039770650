import { accountKitApi, accountKitReducer } from "@nerdjs/account-kit";
import { networkMiddleware, networkReducer } from "@nerdjs/nerd-network";
import { notificationReducer } from "@nerdjs/nerd-ui";
import { combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import { mainMiddleware } from "./mainMiddleware";
import { budgetApi } from "./api/budgetApi";
import { reportReducer } from "./report/reportReducer";
import { appStatusReducer } from "./appStatus/appStatusReducer";

const rootReducer = combineReducers({
  accountKitState: accountKitReducer,
  notification: notificationReducer,
  networkState: networkReducer,
  appStatusState: appStatusReducer,
  reportState: reportReducer,
  [accountKitApi.reducerPath]: accountKitApi.reducer,
  [budgetApi.reducerPath]: budgetApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunkMiddleware,
    budgetApi.middleware,
    mainMiddleware,
    accountKitApi.middleware,
    networkMiddleware, // Always add networkMiddleware after your api middleware
  ],
});

export type AppDispatch = typeof store.dispatch;
