import Report_Entity from "./report";
export default class Report extends Report_Entity {
  static fromList(reportsJSON: unknown): Array<Report> {
    const reports: Report[] = [];
    if (reportsJSON)
      Array.isArray(reportsJSON) &&
        reportsJSON.forEach((reportJSON) => {
          reports.push(new Report(reportJSON));
        });
    return reports;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  hasSeries() {
    let ret = true;
    this.groups.forEach((g) => {
      ret = ret && g.series !== undefined && g.series !== "";
    });

    return ret;
  }

  series() {
    const ret: string[] = [];
    this.groups.forEach((g) => {
      if (ret.indexOf(g.series) === -1) {
        ret.push(g.series);
      }
    });

    return ret;
  }
}
