import TransactionGroup_Entity from "./transactionGroup";
export default class TransactionGroup extends TransactionGroup_Entity {
  static fromList(transactionGroupsJSON: unknown): Array<TransactionGroup> {
    const transactionGroups: TransactionGroup[] = [];
    if (transactionGroupsJSON)
      Array.isArray(transactionGroupsJSON) &&
        transactionGroupsJSON.forEach((transactionGroupJSON) => {
          transactionGroups.push(new TransactionGroup(transactionGroupJSON));
        });
    return transactionGroups;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
