import { useMemo } from "react";
import { Vendor } from "../../entities/vendor";
import { useGetVendorQuery, useGetVendorsQuery } from "./vendorEndpoints";

export function useVendor(
  vendorID: number | undefined,
  opt?: { skip?: boolean }
) {
  const vendorQuery = useGetVendorQuery(vendorID ?? 0, {
    skip: opt?.skip || (vendorID ?? 0) <= 0,
  });
  const vendorEntity = vendorQuery.data;

  return useMemo(() => {
    const ret: typeof vendorQuery & { vendor?: Vendor | undefined } = {
      ...vendorQuery,
    };
    if (vendorEntity) ret.vendor = new Vendor(vendorEntity);
    return ret;
  }, [vendorEntity]);
}

export function useVendors() {
  const vendorQuery = useGetVendorsQuery();
  const vendorEntitiesMap = vendorQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof vendorQuery & {
      vendors?: Vendor[];
      vendorsMap?: { [id: number]: Vendor };
    } = {
      ...vendorQuery,
      vendors: [],
      vendorsMap: {},
    };
    if (vendorEntitiesMap) {
      const vendors = [];
      const vendorsMap = {};

      for (const key in vendorEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(vendorEntitiesMap, key)) {
          const c = vendorEntitiesMap[key];
          const vendor = new Vendor(c);
          vendors.push(vendor);
          vendorsMap[vendor.id] = vendor;
        }
      }
      vendors.sort((a, b) => a.name.localeCompare(b.name));
      ret.vendors = vendors;
      ret.vendorsMap = vendorsMap;
    }

    return ret;
  }, [vendorEntitiesMap]);
}
