import { useMemo } from "react";
import { Account } from "../../entities/account";
import { useGetAccountQuery, useGetAccountsQuery } from "./accountEndpoints";

export function useAccount(
  accountID: number | undefined,
  opt?: { skip?: boolean }
) {
  const accountQuery = useGetAccountQuery(accountID ?? 0, {
    skip: opt?.skip || (accountID ?? 0) <= 0,
  });
  const accountEntity = accountQuery.data;

  return useMemo(() => {
    const ret: typeof accountQuery & { account?: Account | undefined } = {
      ...accountQuery,
    };
    if (accountEntity) ret.account = new Account(accountEntity);
    return ret;
  }, [accountEntity]);
}

export function useAccounts() {
  const accountQuery = useGetAccountsQuery();
  const accountEntitiesMap = accountQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof accountQuery & {
      accounts?: Account[];
      accountsMap?: { [id: number]: Account };
    } = {
      ...accountQuery,
      accounts: [],
      accountsMap: {},
    };
    if (accountEntitiesMap) {
      const accounts = [];
      const accountsMap = {};

      for (const key in accountEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(accountEntitiesMap, key)) {
          const c = accountEntitiesMap[key];
          const account = new Account(c);
          accounts.push(account);
          accountsMap[account.id] = account;
        }
      }
      ret.accounts = accounts;
      ret.accountsMap = accountsMap;
    }

    return ret;
  }, [accountEntitiesMap]);
}
