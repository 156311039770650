import { useMemo } from "react";
import { Transaction } from "../../entities/transaction";
import { useLazyGetReportQuery } from "../report/reportEndpoints";
import {
  useGetTransactionQuery,
  useGetTransactionsQuery,
  useLazyGetTransactionQuery,
} from "./transactionEndpoints";

export function useTransaction(transactionID: number | undefined) {
  const transactionQuery = useGetTransactionQuery(transactionID ?? 0, {
    skip: (transactionID ?? 0) <= 0,
  });
  const transactionEntity = transactionQuery.data;

  return useMemo(() => {
    const ret: typeof transactionQuery & {
      transaction?: Transaction | undefined;
    } = {
      ...transactionQuery,
    };
    if (transactionEntity) ret.transaction = new Transaction(transactionEntity);
    return ret;
  }, [transactionEntity]);
}

export function useTransactions(args?: any) {
  const transactionQuery = useGetTransactionsQuery(args);
  const transactionEntitiesMap = transactionQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof transactionQuery & {
      transactions?: Transaction[];
      transactionsMap?: { [id: number]: Transaction };
    } = {
      ...transactionQuery,
      transactions: [],
      transactionsMap: {},
    };
    if (transactionEntitiesMap) {
      const transactions = [];
      const transactionsMap = {};

      for (const key in transactionEntitiesMap) {
        if (Object.prototype.hasOwnProperty.call(transactionEntitiesMap, key)) {
          const c = transactionEntitiesMap[key];
          const transaction = new Transaction(c);
          transactions.push(transaction);
          transactionsMap[transaction.id] = transaction;
        }
      }
      ret.transactions = transactions;
      ret.transactionsMap = transactionsMap;
    }

    return ret;
  }, [transactionEntitiesMap]);
}
