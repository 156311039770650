import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { TransactionGroup_Entity } from "../../entities/transactionGroup";
import { budgetApi } from "../api/budgetApi";

const transactionGroupsAdapter = createEntityAdapter<TransactionGroup_Entity>();
const initialState = transactionGroupsAdapter.getInitialState();

export const transactionGroupEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getTransactionGroups: build.query<
      EntityState<TransactionGroup_Entity>,
      void
    >({
      query: () => `transactionGroups`,
      transformResponse: (responseData: TransactionGroup_Entity[]) => {
        return transactionGroupsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["transactionGroups"],
    }),
    getTransactionGroup: build.query<TransactionGroup_Entity, number>({
      query: (id) => `transactionGroups/${id}`,
      providesTags: (result, error, id) => [{ type: "transactionGroups", id }],
    }),
    createTransactionGroup: build.mutation<
      TransactionGroup_Entity,
      Partial<TransactionGroup_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `transactionGroups`,
      }),
      invalidatesTags: ["transactionGroups"],
    }),
    updateTransactionGroup: build.mutation<
      void,
      { id: number; body: Partial<TransactionGroup_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `transactionGroups/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "transactionGroups", id },
        "transactionGroups",
      ],
    }),
    deleteTransactionGroup: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `transactionGroups/${id}`,
      }),
      invalidatesTags: ["transactionGroups"],
    }),
  }),
});

export const {
  useGetTransactionGroupQuery,
  useGetTransactionGroupsQuery,
  useCreateTransactionGroupMutation,
  useDeleteTransactionGroupMutation,
  useUpdateTransactionGroupMutation,
} = transactionGroupEndpoints;

export default transactionGroupEndpoints;

export const selectTransactionGroupsResult =
  transactionGroupEndpoints.endpoints.getTransactionGroups.select();

const selectTransactionGroupsData = createSelector(
  selectTransactionGroupsResult,
  (transactionGroupsResult) => transactionGroupsResult.data
);

const store = configureStore({
  reducer: {
    [budgetApi.reducerPath]: budgetApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const {
  selectAll: selectAllTransactionGroups,
  selectById: selectTransactionGroupById,
} = transactionGroupsAdapter.getSelectors<RootState>(
  (state) => selectTransactionGroupsData(state) ?? initialState
);
