import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { store } from "./store";
import { showNotification } from "@nerdjs/nerd-ui";

export const mainMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, unknown>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    // Do stuff
    console.log(`[mainMiddleware]: mainMiddleware sees action:`, api);
    console.log(action);

    if (action.type === "ApiErrorAction") {
      const {
        uuid,
        status,
        description = "",
        message = "",
      } = action.payload.error;

      store.dispatch(
        showNotification({
          notification: {
            uuid: uuid,
            title: `${status} - ${description}`,
            body: message,
            severity: status > 300 && status < 500 ? "warning" : "error",
            autohide: true,
          },
        })
      );
    }

    return next(action);
  };
