import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

const selectFormOpen = (s: RootState) => s.appStatusState.formOpen;
const selectTabIndexes = (s: RootState) => s.appStatusState.tabIndexes;

export const tabIndexSelector = (tabID?: string) => {
  return createSelector([selectTabIndexes], (tabIndexes) => {
    if (tabID) return tabIndexes[tabID];
    return undefined;
  });
};

export const formOpenSelector = (formID?: string) => {
  return createSelector([selectFormOpen], (tabIndexes) => {
    if (formID) return tabIndexes[formID];
    return false;
  });
};
