import { Box } from "@mui/system";
import { ReactElement } from "react";
import { Outlet, useParams } from "react-router-dom";
import ReportsDataGrid from "../hooks/reports/reportsDataGrid";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function ReportsRoute(): ReactElement {
  const params = useParams();
  const reportID = params.reportID ? parseInt(params.reportID) : undefined;

  if (reportID) return <Outlet />;
  return (
    <Box sx={{ p: 1, display: "flex", flex: 1 }}>
      <ReportsDataGrid />
    </Box>
  );
}
