import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { budgetApi } from "../api/budgetApi";
import { Node_Entity } from "../../entities/node";

const nodesAdapter = createEntityAdapter<Node_Entity>();
const initialState = nodesAdapter.getInitialState();

export const nodeEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getNodes: build.query<EntityState<Node_Entity>, void>({
      query: () => `nodes`,
      transformResponse: (responseData: Node_Entity[]) => {
        return nodesAdapter.setAll(initialState, responseData);
      },
      providesTags: ["nodes"],
    }),
    getNode: build.query<Node_Entity, number>({
      query: (id) => `nodes/${id}`,
      providesTags: (result, error, id) => [{ type: "nodes", id }],
    }),
    createNode: build.mutation<Node_Entity, Partial<Node_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `nodes`,
      }),
      invalidatesTags: ["nodes", "sankeys"],
    }),
    updateNode: build.mutation<
      void,
      { id: number; body: Partial<Node_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `nodes/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "nodes", id },
        "nodes",
        "sankeys",
      ],
    }),
    deleteNode: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `nodes/${id}`,
      }),
      invalidatesTags: ["nodes", "sankeys"],
    }),
  }),
});

export const {
  useGetNodeQuery,
  useGetNodesQuery,
  useCreateNodeMutation,
  useDeleteNodeMutation,
  useUpdateNodeMutation,
} = nodeEndpoints;

export default nodeEndpoints;
