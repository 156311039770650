/**
 * Transaction_Entity type guard.
 *
 * @param {any} transactionJson Transaction object from API
 * @returns {boolean} Return true if type is Transaction_Entity
 */
function validator(
  transactionJson: unknown
): transactionJson is Transaction_Entity {
  if (typeof transactionJson === "object" && transactionJson != null) {
    Transaction_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(transactionJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Transaction_Entity {
  static requiredFields = [
    "id",
    "refID",
    "kind",
    "amount",
    "descrition",
    "vendorID",
    "accountID",
    "expenseTypeID",
    "date",
    "dateFrom",
    "dateTo",
    "createdAt",
    "updatedAt",
  ];

  constructor(transactionJson: unknown) {
    if (validator(transactionJson)) {
      this.id = transactionJson.id;
      this.refID = transactionJson.refID;
      this.kind = transactionJson.kind;
      this.amount = transactionJson.amount;
      this.descrition = transactionJson.descrition;
      this.vendorID = transactionJson.vendorID;
      this.accountID = transactionJson.accountID;
      this.expenseTypeID = transactionJson.expenseTypeID;
      this.date = transactionJson.date;
      this.dateFrom = transactionJson.dateFrom;
      this.dateTo = transactionJson.dateTo;
      this.createdAt = transactionJson.createdAt;
      this.updatedAt = transactionJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Transaction_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  refID: number;
  kind: string;
  amount: number;
  descrition: string;
  vendorID: number;
  accountID: number;
  expenseTypeID: number;
  date: string;
  dateFrom: string;
  dateTo: string;
  createdAt: string;
  updatedAt: string;
}
