import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button } from "@mui/joy";
import { Paper } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useConfirm } from "material-ui-confirm";
import { Vendor_Entity } from "../../entities/vendor";
import {
  useCreateVendorMutation,
  useDeleteVendorMutation,
  useUpdateVendorMutation,
} from "../../redux/vendor/vendorEndpoints";
import { useVendors } from "../../redux/vendor/vendorHooks";

/**
 * Vendor
 *
 * @returns {null} Vendor
 */
export default function VendorsDataGrid() {
  const { vendors, isLoading } = useVendors();
  const [updateVendor] = useUpdateVendorMutation();
  const [deleteVendor] = useDeleteVendorMutation();

  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  const onVendorUpdate = async (
    _newVendor: Vendor_Entity,
    _oldVendor: Vendor_Entity
  ) => {
    return new Promise<Vendor_Entity>((resolve) => {
      updateVendor({
        id: _newVendor.id,
        body: _newVendor,
      });
      setTimeout(() => {
        resolve({ ..._newVendor });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1, display: "flex" }}>
      <DataGridPro
        density="compact"
        apiRef={apiRef}
        loading={isLoading}
        processRowUpdate={onVendorUpdate}
        rows={vendors ?? []}
        columns={[
          {
            field: "id",
            headerName: "ID",
            editable: false,
            type: "number",
            width: 40,
          },
          {
            field: "name",
            headerName: "Name",
            editable: true,
            type: "string",
            flex: 1,
          },
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (p) => [
              <GridActionsCellItem
                key="delete"
                icon={<DeleteIcon />}
                label={`Delete`}
                onClick={() => {
                  confirm({
                    description: `You are about to delete a vendor`,
                  })
                    .then(() => {
                      deleteVendor(p.row.id);
                    })
                    .catch(() => {
                      /* ... */
                    });
                }}
                color="inherit"
              />,
            ],
          },
        ]}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: VendorToolbar,
        }}
        initialState={{
          pinnedColumns: { left: ["id"], right: ["actions"] },
        }}
      />
    </Paper>
  );
}

function VendorToolbar() {
  const [createVendor] = useCreateVendorMutation();

  const handleNewVendor = () => {
    const newVendor = {
      id: -1,
      name: "",
      createdAt: "",
      updatedAt: "",
    };
    createVendor(newVendor);
  };

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, alignItems: "start", gap: 1 }}
    >
      <Button variant="soft" onClick={handleNewVendor}>
        + New
      </Button>
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}
