import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button } from "@mui/joy";
import { Paper } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useConfirm } from "material-ui-confirm";
import { TransactionGroup_Entity } from "../../entities/transactionGroup";
import {
  useCreateTransactionGroupMutation,
  useDeleteTransactionGroupMutation,
  useUpdateTransactionGroupMutation,
} from "../../redux/transactionGroup/transactionGroupEndpoints";
import { useTransactionGroups } from "../../redux/transactionGroup/transactionGroupHooks";

/**
 * TransactionGroup
 *
 * @returns {null} TransactionGroup
 */
export default function TransactionGroupsDataGrid() {
  const { transactionGroups, isLoading } = useTransactionGroups();
  const [updateTransactionGroup] = useUpdateTransactionGroupMutation();
  const [deleteTransactionGroup] = useDeleteTransactionGroupMutation();

  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  const onTransactionGroupUpdate = async (
    _newTransactionGroup: TransactionGroup_Entity,
    _oldTransactionGroup: TransactionGroup_Entity
  ) => {
    return new Promise<TransactionGroup_Entity>((resolve) => {
      updateTransactionGroup({
        id: _newTransactionGroup.id,
        body: _newTransactionGroup,
      });
      setTimeout(() => {
        resolve({ ..._newTransactionGroup });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1, display: "flex" }}>
      <DataGridPro
        density="compact"
        apiRef={apiRef}
        loading={isLoading}
        processRowUpdate={onTransactionGroupUpdate}
        rows={transactionGroups ?? []}
        columns={[
          {
            field: "id",
            headerName: "ID",
            editable: false,
            type: "number",
            width: 40,
          },
          {
            field: "name",
            headerName: "Name",
            editable: true,
            type: "string",
            flex: 1,
          },
          {
            field: "description",
            headerName: "Description",
            editable: true,
            type: "string",
            flex: 1,
          },
          {
            field: "typeIDs",
            headerName: "TypeIDs",
            editable: true,
            type: "string",
            flex: 1,
          },
          {
            field: "vendorIDs",
            headerName: "VendorIDs",
            editable: true,
            type: "string",
            flex: 1,
          },
          {
            field: "accountIDs",
            headerName: "AccountIDs",
            editable: true,
            type: "string",
            flex: 1,
          },
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (p) => [
              <GridActionsCellItem
                key="delete"
                icon={<DeleteIcon />}
                label={`Delete`}
                onClick={() => {
                  confirm({
                    description: `You are about to delete a transactionGroup`,
                  })
                    .then(() => {
                      deleteTransactionGroup(p.row.id);
                    })
                    .catch(() => {
                      /* ... */
                    });
                }}
                color="inherit"
              />,
            ],
          },
        ]}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: TransactionGroupToolbar,
        }}
        initialState={{
          pinnedColumns: { left: ["id"], right: ["actions"] },
        }}
      />
    </Paper>
  );
}

function TransactionGroupToolbar() {
  const [createTransactionGroup] = useCreateTransactionGroupMutation();

  const handleNewTransactionGroup = () => {
    const newTransactionGroup = {
      id: -1,
      name: "",
      createdAt: "",
      updatedAt: "",
    };
    createTransactionGroup(newTransactionGroup);
  };

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, alignItems: "start", gap: 1 }}
    >
      <Button variant="soft" onClick={handleNewTransactionGroup}>
        + New
      </Button>
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}
