import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { Account_Entity } from "../../entities/account";
import { budgetApi } from "../api/budgetApi";

const accountsAdapter = createEntityAdapter<Account_Entity>();
const initialState = accountsAdapter.getInitialState();

export const accountEndpoints = budgetApi.injectEndpoints({
  endpoints: (build) => ({
    getAccounts: build.query<EntityState<Account_Entity>, void>({
      query: () => `accounts`,
      transformResponse: (responseData: Account_Entity[]) => {
        return accountsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["accounts"],
    }),
    getAccount: build.query<Account_Entity, number>({
      query: (id) => `accounts/${id}`,
      providesTags: (_result, _error, id) => [{ type: "accounts", id }],
    }),
    createAccount: build.mutation<Account_Entity, Partial<Account_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `accounts`,
      }),
      invalidatesTags: ["accounts"],
    }),
    updateAccount: build.mutation<
      void,
      { id: number; body: Partial<Account_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `accounts/${args.id}`,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "accounts", id },
        "accounts",
      ],
    }),
    deleteAccount: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `accounts/${id}`,
      }),
      invalidatesTags: ["accounts"],
    }),
  }),
});

export const {
  useGetAccountQuery,
  useGetAccountsQuery,
  useCreateAccountMutation,
  useDeleteAccountMutation,
  useUpdateAccountMutation,
} = accountEndpoints;

export default accountEndpoints;

export const selectAccountsResult =
  accountEndpoints.endpoints.getAccounts.select();

const selectAccountsData = createSelector(
  selectAccountsResult,
  (accountsResult) => accountsResult.data
);

const store = configureStore({
  reducer: {
    [budgetApi.reducerPath]: budgetApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllAccounts, selectById: selectAccountById } =
  accountsAdapter.getSelectors<RootState>(
    (state) => selectAccountsData(state) ?? initialState
  );
