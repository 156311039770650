import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Box, Button, Chip } from "@mui/joy";
import { Paper } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarQuickFilter,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import { Score_Entity } from "../../entities/score";
import {
  useCreateScoreMutation,
  useDeleteScoreMutation,
  useUpdateScoreMutation,
} from "../../redux/score/scoreEndpoints";
import { useScores } from "../../redux/score/scoreHooks";

/**
 * Score
 *
 * @returns {null} Score
 */
export default function ScoresDataGrid() {
  const { scores, isLoading } = useScores();
  const [updateScore] = useUpdateScoreMutation();
  const [deleteScore] = useDeleteScoreMutation();

  const apiRef = useGridApiRef();
  const confirm = useConfirm();

  const onScoreUpdate = async (
    _newScore: Score_Entity,
    _oldScore: Score_Entity
  ) => {
    return new Promise<Score_Entity>((resolve) => {
      updateScore({
        id: _newScore.id,
        body: _newScore,
      });
      setTimeout(() => {
        resolve({ ..._newScore });
      }, 50);
    });
  };

  return (
    <Paper sx={{ flex: 1, display: "flex" }}>
      <DataGridPro
        apiRef={apiRef}
        loading={isLoading}
        processRowUpdate={onScoreUpdate}
        rows={scores ?? []}
        columns={[
          {
            field: "id",
            headerName: "ID",
            editable: false,
            type: "number",
            width: 40,
          },
          {
            field: "date",
            headerName: "Date",
            editable: false,
            type: "date",
            valueFormatter: (p) => moment(p.value).format("MMM YY"),
          },
          {
            field: "quotaGrade",
            headerName: "Quota Grade",
            editable: false,
            type: "number",
            renderCell: (p) => <ScoreChip score={p.value} />,
          },
          {
            field: "plGrade",
            headerName: "P&L Grade",
            editable: false,
            type: "number",
            renderCell: (p) => <ScoreChip score={p.value} />,
          },
          {
            field: "grade",
            headerName: "Grade",
            editable: false,
            type: "number",
            renderCell: (p) => <ScoreChip score={p.value} />,
          },
          {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: (p) => [
              <GridActionsCellItem
                key="delete"
                icon={<DeleteIcon />}
                label={`Delete`}
                onClick={() => {
                  confirm({
                    description: `You are about to delete a score`,
                  })
                    .then(() => {
                      deleteScore(p.row.id);
                    })
                    .catch(() => {
                      /* ... */
                    });
                }}
                color="inherit"
              />,
            ],
          },
        ]}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: ScoreToolbar,
        }}
        initialState={{
          pinnedColumns: { left: ["id"], right: ["grade", "actions"] },
        }}
      />
    </Paper>
  );
}

function ScoreToolbar() {
  const [createScore] = useCreateScoreMutation();

  const handleNewScore = () => {
    const newScore = {
      id: -1,
      name: "",
      createdAt: "",
      updatedAt: "",
    };
    createScore(newScore);
  };

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, alignItems: "start", gap: 1 }}
    >
      <Button variant="soft" onClick={handleNewScore}>
        + New
      </Button>
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
    </Box>
  );
}

export function ScoreChip({
  score = 0,
  size = "sm",
}: {
  score: number;
  size?: "sm" | "lg";
}) {
  let icon = "🤩";
  let color:
    | "primary"
    | "neutral"
    | "danger"
    | "info"
    | "success"
    | "warning"
    | undefined = "success";
  if (score < 800) {
    color = "warning";
    icon = "🤔";
  }
  if (score < 600) {
    color = "danger";
    icon = "🤬";
  }

  return (
    <Chip
      startDecorator={
        size === "lg" ? <span style={{ fontSize: 22 }}>{icon}</span> : ""
      }
      variant="soft"
      size={size}
      color={color}
    >
      {score}
    </Chip>
  );
}
