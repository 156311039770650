import { Box } from "@mui/system";
import { ReactElement } from "react";
import QuotasDataGrid from "../hooks/quotas/quotasDataGrid";

/**
 *
 * @returns {ReactElement} Nerd route
 */
export default function QuotasRoute(): ReactElement {
  return (
    <Box sx={{ p: 1, display: "flex", flex: 1 }}>
      <QuotasDataGrid />
    </Box>
  );
}
