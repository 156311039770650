import {
  GridValueGetterParams,
  GridValueSetterParams,
} from "@mui/x-data-grid-pro";

export const getterDivider =
  (key: string, multipler: number) => (e: GridValueGetterParams) => {
    return e.row[key] / multipler;
  };

export const setterDivider =
  (key: string, multipler: number) => (p: GridValueSetterParams) => {
    return { ...p.row, [key]: Math.round(p.value * multipler) };
  };

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});
