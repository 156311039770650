import Sankey_Entity from "./sankey";
export default class Sankey extends Sankey_Entity {
  static fromList(sankeysJSON: unknown): Array<Sankey> {
    const sankeys: Sankey[] = [];
    if (sankeysJSON)
      Array.isArray(sankeysJSON) &&
        sankeysJSON.forEach((sankeyJSON) => {
          sankeys.push(new Sankey(sankeyJSON));
        });
    return sankeys;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
