import { createReducer } from "@reduxjs/toolkit";
import { queryBuilder } from "../../network";
import { setSelectedReportID } from "./reportActions";
import { ReportState } from "./reportState";

function initState(): ReportState {
  const { report: defaultSelectRowID } = queryBuilder.getQuery();
  return {
    selectedReportID: !isNaN(Number(defaultSelectRowID))
      ? Number(defaultSelectRowID)
      : undefined,
  };
}

export const reportReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedReportID, (state, action) => {
    state.selectedReportID = action.payload.reportID;
    return state;
  });
});
